import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab, Box, Divider, Typography, Button, IconButton, Container, LinearProgress } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useNavigate, useLocation, useParams, useNavigation, Outlet, useLoaderData } from "react-router-dom";
import './Dashboard.css'
import { GoogleMap } from './GoogleMap';
import { ActivityPanel } from './ActivityPanel';
import { styled } from '@mui/system';
import { Stepper, Step, StepLabel, TextField } from '@mui/material';
import TruckOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { listAndCreateRequestedShipments, getShipments, getTrips, getGps } from '../../services/ApiService'
import { useSocketConnection, createSocketConnection, ChatRoom } from '../../services/socketConnection';
import { styles } from './StyledComponents'; // Importing the styles object
import { EggAlt, LastPageOutlined } from '@mui/icons-material';



// TODO: remove one of 2 socket connections
export function ShipmentInfo() {
    const { tracking } = useParams();
    const { state } = useLocation()
    const connection = useRef();

	const [route, setRoute] = useState(null);
    const [shipment, setShipment] = useState(null);
    const [events, setEvents] = useState([]);
    const navigation = useNavigate();

    // const { shipment, } = useLoaderData();
    // console.log("useLocation", state)

    useEffect(() => {
        let ignore = false;

        const fetchData = async () => {
            let shipment = state?.shipment;
            
            if (shipment) {
		        window.history.replaceState({}, '')
            } else {
                const result = await getShipments({ tracking }); // ios prod
                shipment = result.pop();
                if (!shipment) {
                    return navigation('/');
                }
            }

            if (!ignore) {
                // console.log('shipmentttttttttttttt', shipment.events.slice())
                setShipment(shipment);
                setEvents(shipment.events);
            }
        };

        fetchData();

        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        const removeHandlerCallbacks = [];
        const conn = createSocketConnection({ url: process.env.REACT_APP_SOCKET_URL + "/shipments/" + tracking });
        connection.current = conn;
        const cleanup = conn.connect();

        const removeChatMessage = conn.on('chat.message', (e) => {
            console.log('connection message:', JSON.parse(e?.data))
            let data = JSON.parse(e.data);

            // WE NEED TO UPDATE COMPONENT STATE HERE TO SHOW NEW EVENT/MESSAGE
            // const event =  {shipment_id: 2, trip_id: 1, type: 'MESSAGE', type_display: 'Message', timestamp: data.time_sent, triggered_by: {first_name: 'Duevyn', last_name: 'Cooke',}, message_text: data.data.message, gpt_message: data.data.gpt_message };
            if (data.type === 'chat.message') {
                setEvents(prevEvents => [...prevEvents, data.data2])
            }
        });
        removeHandlerCallbacks.push(removeChatMessage);

        return () => {
            removeHandlerCallbacks.forEach(callback => {
                callback();
            });

            cleanup?.();
        };
    }, []);

    if (!shipment) {
        return (
            <>Loading</>
        );
    }

    let index = Math.max(0, shipment.trips.findIndex(trip => trip.status === "IN_PROGRESS"));
    const trip = shipment.trips[index]

    index = Math.max(0, trip.stops.findIndex(stop => stop.status === "ACTIVE"));
    const stop = trip.stops[index]
    const [origin, destination] = shipment.stops;

    console.log('stop', stop)
    console.log('trip', trip)
    console.log('shipment', shipment)

    return (
        <styles.ShipmentDetailContainer id="shipment-detail-container">
            {/* <styles.SideMenu id='side-menu' sx={{}} className=''>
                <styles.IconBuffer><TruckOutlinedIcon /></styles.IconBuffer>
                <styles.IconBuffer><CalendarMonthOutlinedIcon /></styles.IconBuffer>
                <styles.IconBuffer><DescriptionOutlinedIcon /></styles.IconBuffer>
                <styles.IconBuffer><SettingsOutlinedIcon /></styles.IconBuffer>
                <styles.IconBuffer></styles.IconBuffer>
            </styles.SideMenu> */}
            <styles.MainContent id='main-content'>
                    <styles.ShipmentInfo id='shipment-info'>
                        <Box sx={{display: 'flex', flex:1, flexDirection: 'column'}}>
                            <Box sx={{}}> </Box>

                            {/* Google Map */}
                            <Box style={{ marginTop: "0px", flexBasis: '100%', }}>
                                <GoogleMap connection={connection.current} trip={trip} setRoute={setRoute}></ GoogleMap>
                            </Box>
                            {/* {chatInput} */}

                            <InfoPanel shipment={shipment} route={route} stop={stop} origin={origin} destination={destination}></InfoPanel>
                        </Box>
                    </styles.ShipmentInfo>

                    <Box sx={{ display: 'flex', maxWidth: '100%', padding: '0px', justifyContent: 'space-between', right: 0, flexDirection: 'column', flex: .333, backgroundColor: '', height: '100%'}}>
                        <ActivityPanel id='activity-panel' info={{consignee: shipment.consignee.id, consignor: shipment.consignor.id, broker: 1, carrier: trip.driver.company}} events={events} drivers={shipment.trips.reduce( (drivers, trip) => {
                            drivers[trip.driver.id] = true;
                            return drivers;
                            }, {})}> </ActivityPanel>
                        {(shipment && connection.current) && <>
                            <ChatRoom connection={connection.current} tripId={trip.id} shipmentId={shipment.id}></ChatRoom>
                        </>}
                    </Box>

            </styles.MainContent>
        </styles.ShipmentDetailContainer>
    );
};

function InfoPanel({shipment, stop, route, origin, destination}){
    const trip = shipment.trips[0];
    if (!shipment && !route){
        return;
    }

    return (
        
        <Box id='shipment-header' sx={{ display: 'flex', alignItems:'center', flexWrap: 'wrap',flex: 1, justifyContent: 'start', alignItems: 'start' , alignSelf: 'stretch',  backgroundColor: '#FAFAFA', p:'.5em .5em'}}>

                <Box my='auto'>
                    <Typography variant='body1' component='div'> Shipment  <strong>{shipment?.tracking_number}</strong> </Typography>
                    {/* <Typography variant='body2'> {shipment && shipment.name} </Typography> */}
                    <Typography variant='h6' component='div' backgroundColor='lightblue' align='center' my={1} sx={{ fontWeight: 600, borderRadius: '1em', mx: '1', padding: '' }}>
                        {shipment.status_display}
                    </Typography>
                </Box>

                <Divider orientation='vertical' sx={{ height: '100%', m:'auto .2em'}} flexItem />
                { route && <RouteDisplay route={route}></RouteDisplay> }

                <Divider orientation='vertical' sx={{ height: '100%', m:'auto .2em'}} flexItem />
                <StopInfo stop={origin} head={'Origin'} activeStopId={stop.id}></StopInfo>

                <Divider orientation='vertical' sx={{ height: '100%', m:'auto .2em'}} flexItem />
                <StopInfo stop={destination} head={'Destination'} activeStopId={stop.id}></StopInfo>

                <Divider orientation='vertical' sx={{ height: '100%', m:'auto .2em'}} flexItem />
                <styles.SpacerBox  />


                {/* <Divider orientation='vertical' sx={{ height: '100%', m:'auto .2em'}} flexItem /> */}
                <Divider orientation='vertical' sx={{ height: '100%', m:'auto .2em'}} flexItem />
                <Contacts broker={shipment.managed_by} driver={trip.driver}></Contacts>
        </Box>
    );

}

function Contacts({broker, driver}){
    return(
        <Box>
            <Box display='flex'>
                <AddIcCallIcon sx={{ fontSize: 'large', color: 'white', p: ' .5em', mr: '.5em', borderRadius: '1em', border: '1px solid #512ae5', backgroundColor: '#ccc' }} />
                <Typography variant='body2' backgroundColor='' ml='.3em'>
                    <strong>{broker.company_name} </strong><br />
                    Duevyn Cooke <small>(broker)</small> <br /> 757 688 4589
                </Typography>
            </Box>
            <Divider orientation='horizontal' sx={{m: '.5em auto', width: '95%'}}></Divider>

            <Box display='flex'>
                <LocalShippingOutlinedIcon  sx={{ fontSize: 'large', color: 'white', p: ' .5em', mr: '.5em', borderRadius: '1em', border: '1px solid black', backgroundColor: '#512ae5' }} />
                <Typography variant='body2' backgroundColor='' ml='.3em'>
                    <strong>{driver.company_name} </strong><br />
                    Duevyn Cooke <small>(driver)</small> <br /> 757 688 4589
                </Typography>
            </Box>
        </Box>
    );
}
function StopInfo({stop, head, activeStopId}){
    function toLocalString(timestamp){
        let string =  new Date(timestamp).toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
        return string.split(', ')
    };

    let className, backgroundColor, border, padding, statusBackground;
    let value = 0

    console.log('stopinfo', {stop, activeStopId, head})
    
    switch (stop.status){
        case 'ACTIVE':
            value = 50;
            break;
        case 'PENDING':
            value = 0;
            break;
        default:
            value = 100;
            break;
    }

    if (stop.id === activeStopId){

        backgroundColor = stop.status ==='ACTIVE' ? 'lightblue' : '#e9e9e9'
        className = stop.id === activeStopId ? 'active' : '';
        border = '5px solid #512ae5';
        // border = '4px solid greenyellow';
        padding = '.1em .1em';
        statusBackground = '#fafafa'
    } else {
        border='5px solid transparent'
        statusBackground = '#e9e9e9'
        padding = '.1em .1em';
    }

    let [day, month, time] = toLocalString(stop.appointment_time)
    return (
        <Box className={className} sx={{backgroundColor, border, padding,}} alignSelf='stretch'  minHeight='calc(100% - .6em)' display='flex' flexDirection='column'>
            <Box display='flex'>
                <Box m='auto'>

                <Typography align='center' variant='h6' component='div'> <strong>{stop.location.city}, {stop.location.state}</strong> {stop.id === activeStopId && <span sx={{backgroundColor: 'lightgreen'}}><Box textAlign='center' backgroundColor='lightgreen'  p='.1em' display='inline'><small><small> &#160; next &#160;</small></small></Box></span>}</Typography>
                </Box>
            </Box>
                <Box ><LinearProgress variant='determinate' flex={1} value={value}/></Box>
                <Typography variant='caption' borderRadius='2em' align='center' component='div' my='.2em' backgroundColor={statusBackground}><strong>Status: </strong>{stop.status_display}</Typography>
            <Typography variant='caption' color='darkslategray' component='div'>{time} {month}, {day}</Typography>
            <Typography variant='body2' component='div'>
                {`${stop.location.street}`}<br />
                {`${stop.location.city}, ${stop.location.state} ${stop.location.zip}`}<br />
            </Typography>
        </Box>
    );
}

function RouteDisplay({ route }){

    if (!route){
        return;
    }

    let value = 0;
    const leg = route.legs[0];
    console.log('leg', leg)
    let startCity = leg.start_address.split(', ')[1]
    let endCity = leg.end_address.split(', ')[1]
    return (
        <Box display='flex' flexDirection='column' alignItems='start' textAlign='left' my='.1em' sx={{border: '5px solid transparent', maxWidth:'20vw'}}>
            <Box  textAlign='left' >
                <Typography variant='h6'><strong>{startCity} to {endCity}</strong></Typography>
                {/* <Typography variant='h6'><strong>Next stop in: </strong>{route.summary} </Typography> */}
                <Box ><LinearProgress variant='determinate' flex={1} value={value}/></Box>
                <Typography variant='caption' borderRadius='2em' align='center' component='div' my='.2em' backgroundColor='#e9e9e9'><strong>Status: </strong>upcoming</Typography>
                <Typography variant='body1'><strong>Route: </strong>{route.summary}</Typography>
                {/* <Typography variant='body1'>dist:  {leg.distance.text} time: {leg.duration.text}</Typography> */}
                <Typography variant='body1'>{leg.duration.text} &#40;{leg.distance.text}&#41;</Typography>
                {/* <Typography variant='body1'> </Typography> */}
            </Box>
        </Box>
    );
}

function StatusStepper() {
    <StepLabel >Load 10/11/23: Norfolk, VA</StepLabel>
    return (
        <Stepper activeStep={4} alternativeLabel >
            <Step>
                <StepLabel >Tender</StepLabel>
            </Step>
            <Step>
                <StepLabel >Quote</StepLabel>
            </Step>
            <Step>
                <StepLabel >Accept</StepLabel>
            </Step>
            <Step>
                <StepLabel >Schedule</StepLabel>
            </Step>
            <Step>
                <StepLabel >Transit</StepLabel>
            </Step>
            <Step>
                <StepLabel >Deliver</StepLabel>
            </Step>
            <Step>
                <StepLabel >Invoice</StepLabel>
            </Step>
            <Step>
                <StepLabel >Pay</StepLabel>
            </Step>
        </Stepper>
    );
}
function SearchBar({ filterText, inStockOnly }) {
    return (
        <form>
            <input
                type="text"
                value={filterText}
                placeholder="Search..." />
            <label>
                <input
                    type="checkbox"
                    checked={inStockOnly} />
                {' '}
                Only show products in stock
            </label>
        </form>
    );
}

{/* <Box sx={{ display: 'flex', flexBasis: '100%', }}>
    <Box sx={{ display: 'flex', }}>

        {<Tabs value={'value'} sx={{ fontSize: '20px' }} onChange={() => { }}>
            {shipment && shipment.stops?.map((stop, index) => (
                <Tab key={index} label={stop.location.city} sx={{ display: 'block', fontSize: '12px' }}>
                </Tab>
            ))}
        </Tabs>}
    </Box>
</Box>

<styles.RocketPanel>
    <Typography style={{ padding: '0 15px', fontWeight: 700 }} variant='h5'>Trip: {shipment && shipment.trips[0].name}</Typography>
    <Typography style={{ padding: '0 15px', backgroundColor: 'lightgreen', borderRadius: '5%', }} variant='h6'>{shipment && shipment.trips[0].status_display}</Typography>

    {shipment && shipment.trips.map((trip, ind) => (
        <styles.StopsSection id='stops-section' key={ind} >
            {trip.stops.map((stop, index) => (
                <styles.StopPanel id='stop-panel' key={index} >
                    <styles.StopPanelHeader >Stop {index + 1}: {stop.type_display}</styles.StopPanelHeader>
                    <Box flex={1} className={index === 0 ? 'stop-body left-edge' : 'stop-body green'}>

                        <Box flexBasis='100%' fontWeight='700'>{stop.location.name}</Box>
                        <Box flexBasis='100%'>{stop.address}</Box>

                        {stop.status != 'DONE' ? (<Box flexBasis='100%'>Appt: {new Date(stop.appointment_time).toLocaleString()}</Box>) : (<Box flexBasis='100%'>{new Date(stop.completion_time).toLocaleString()}</Box>)}
                        <Box flexBasis='100 %'>{stop.status_display}</Box>
                    </Box>
                </styles.StopPanel>
            ))}
            <styles.StopPanel >
                <styles.StopPanelHeader >{trip.driver.first_name} {trip.driver.last_name}</styles.StopPanelHeader>
                <Box className='stop-body' flex={1}>

                    <Box flexBasis='100%'>{trip.driver.primary_phone}</Box>
                    <Box flexBasis='100%'>{trip.driver.company_name}</Box>
                    <Box flexBasis='100%'>{trip.vehicle.type_display}</Box>
                    <Box flexBasis='100%' >{trip.vehicle.plate_number}</Box>
                </Box>
            </styles.StopPanel>

            <styles.StopPanel>
                <styles.StopPanelHeader>Product Info</styles.StopPanelHeader>
                <Box className='stop-body right-edge' flex={1}>
                    <Box flexBasis='100%'>6 Pallets Dry Goods</Box>
                    <Box flexBasis='100%'>Weight: 22,000 lbs</Box>
                    <Box flexBasis='100%'>Volume: 350 sq ft</Box>
                    <Box flexBasis='100%'>Seal: 625-3936832</Box>
                </Box>
            </styles.StopPanel>
        </styles.StopsSection>
    ))}
    <Box sx={{ backgroundColor: 'white', display: 'flex', border: '2px solid #3ca9f5', flex: 1, justifyContent: 'space-between', mx: '15px', p: '5px 15px', }}>
        <Box flex={1} sx={{ justifyContent: 'space-around', }}>
            <Button variant='contained' size='small' sx={{ padding: '0px 10px', mx: '10px', borderRadius: '0px', backgroundColor: '#3ca9f5', }}>Add Stop</Button>
        </Box>
        <Box flex={1} justifyContent='flex-end' display='flex' >

            <RequestQuoteIcon sx={{ color: 'blue' }} />
            <EventNoteIcon sx={{ color: '#3ca9f5' }} />
        </Box>
    </Box>
</styles.RocketPanel>

{shipment && <styles.AdditionalInfo>
    <styles.AdditionalInfoSection>
        <Box className='info-header'>Shipper</Box>
        <Box fontWeight={700}>{shipment.consignor.name}</Box>
        <Typography>Contact: {shipment.consignor.contact_name} {shipment.consignor.contact_phone}</Typography>
        <Typography>1234 Mock Address Ln,</Typography>
        <Typography>Seattle, WA 27119</Typography>
    </styles.AdditionalInfoSection>
    <styles.AdditionalInfoSection>
        <Box className='info-header'>Consignee</Box>
        <Box fontWeight={700}>{shipment.consignee.name}</Box>
        <Typography>Contact: {shipment.consignee.contact_name} {shipment.consignee.contact_phone}</Typography>
        <Typography>5466 Lakeview Rd,</Typography>
        <Typography>Seattle, WA 27119</Typography>
    </styles.AdditionalInfoSection>
    <styles.AdditionalInfoSection>
        <Box className='info-header'>Billing Party</Box>
        <Box fontWeight={700}>TMG Logistics</Box>
        <Typography>Contact: Duevyn Cooke 757-688-4589</Typography>
        <Typography>4321 Mock Address Ln,</Typography>
        <Typography>Seattle, WA 27119</Typography>
    </styles.AdditionalInfoSection>
</styles.AdditionalInfo>} */}