import React, { useEffect } from 'react';
import  { QuoteForm } from './QuoteForm';
import './Quote.css';
import Box from '@mui/material/Box';
import { Container, Grid, TextField, Button, Paper, Typography, Divider } from '@mui/material';
import digitalSupplyChain from './dschain.png';

import connect from '../../images/connect.svg';
import automate from '../../images/automate.svg';
import deliver from '../../images/Deliver.svg';

export function Quote(){
  useEffect(()=>{},[])
  return (
    <Container maxWidth="lg" id='' >
      <Grid container spacing={0} id='main-grid'>

        {/* Left column for Quote Form */}
        <Grid item xs={12} sm={9} md={5} className=''>
          <QuoteForm/>
        </Grid>

        {/* Right column for advertisement */}
        <Grid item xs={12} md={6} className='test'>
          <Paper elevation={0} className="paper-container">
            <Typography variant="h4" gutterBottom>
              Streamline Your Logistics with Photon!
            </Typography>
            <Typography variant="body1" paragraph>
              We're not just a software company; we're a 3PL provider committed to simplifying and optimizing your supply chain. As our valued logistics client, gain complimentary access to our state-of-the-art software platform.
            </Typography>
            <Typography variant="body1" paragraph>
              Manage all facets of your logistics—from Inventory Tracking to Order Picking and Routing—within a single, integrated platform.
            </Typography>
            <Typography variant="body1">
              Unlock the Power of Data:
              <ul>
                <li>Identify your best-selling products and prioritize stocking them.</li>
                <li>Measure the ROI of your advertising campaigns to allocate resources effectively.</li>
                <li>Spot high-value customers and create targeted marketing strategies.</li>
              </ul>
            </Typography>
            <Typography variant="body1" paragraph>
              Enjoy these features and data-driven insights fully integrated into your logistics workflow—at zero additional cost to you. Transform your logistics operations with TMG today!
            </Typography>
          </Paper>
        </Grid>


        {/* start of how it works sections */}
        <Grid item xs={12}>
          <Divider style={{ width: '100%', margin: '10px 0px' }} />
        </Grid>

        <Grid item xs={12} className='center-horizontally'>
            <Typography variant="h5" gutterBottom>How It Works</Typography>
        </Grid>
        <Grid item xs={10} className='center-horizontally'>
          <Typography variant='body2' className='' paragraph>
            We simplify logistics in simple steps. Effortlessly connect and sync your inventory with our adaptive platform. Next, experience smart automation as we intuitively manage your orders,
            keeping your supply in perfect tune with demand. Finally, count on our timely, secure deliveries. With us, logistics is efficient, freeing you to focus on growth.
          </Typography>
        </Grid>

        <Grid container className='' spacing={0}>
          <Grid item xs={12} className='center-vertically'>
            {/* Empty or banner content */}
          </Grid>

          {/* 1st photo */}
          <Grid item xs={12} sm={4} className='center-vertically'>
            <div className='image-container'>
              <img src={connect} alt="Connect, Import, & Integrate" className='responsive-image'/>
              <h4 className='image-caption'>1. Connect, Import, & Integrate</h4>
              <p>Connect to the Photon platform, import your inventory, and integrate with current services.</p>
            </div>
          </Grid>

          {/* 2nd photo */}
          <Grid item xs={12} sm={4} className='center-vertically'>
            <div className='image-container'>
              <img src={automate} alt="Automate Supply Chain" className='responsive-image'/>
              <h4 className='image-caption'>2. Automate Supply Chain</h4>
              <p>Configure system to manage inventory, interpret/make orders, and notify Photon to facilitate delivery.</p>
            </div>
          </Grid>

          {/* 3rd photo */}
          <Grid item xs={12} sm={4} className='center-horizontally'>
            <div className='image-container'>
              <img src={deliver} alt="We Deliver" className='responsive-image'/>
              <h4 className='image-caption'>3. Hands-free Delivery</h4>
              <p>The Photon Integration Platform guarantees hassle-free, timely, secure delivery!</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
