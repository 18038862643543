import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const styles = {
    ShipmentDetailContainer: styled(Box)({
        display: 'flex',
        maxHeight: '100%',
        height: '100%',
        flex: 1,
    }),
    SideMenu: styled(Box)({
        width: '3vh',
        //backgroundColor: 'green',
        display: 'flex',
        //height: '95.5vh',
        //overflowY: 'hidden',
        flexDirection: 'column',
        left: 0,
        alignItems: 'center',
        //padding: '10px 0',
        //justifyContent: 'center',
        justifyItems: 'stretch',
    }),
    MainContent: styled(Box)({
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'stretch',
        alignContent: 'start',
        height: '100%',
        maxHeight: '100%',
    }),
    ShipmentHeader: styled(Box)({
        display: 'flex',
        alignSelf: 'start',
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: '#fafafa',
        alignItems: 'center',
        // height: '5vh',
        // maxHeight: '5vh',
    }),
    ShipmentBody: styled(Box)({
        flex: 1,
        display: 'flex',
        // maxHeight: 'calc(100% - 1vh)',
        // height: 'calc(100% - 1vh)',
        backgroundColor: 'orange',
        height: '100%',
    }),
    ShipmentInfo: styled(Box)({
        display: 'flex',
        //flexDirection: 'column',
        flexWrap: 'wrap',
        flex: 1,
        // backgroundColor: '#e8e8e8',
        // backgroundColor: '#f5f5f5',
        overflowY: 'auto',
        //alignSelf: 'start', // stops height from stretching to height of activity panel
        // alignContent: 'start',
        //justifySelf: 'start',
        alignItems: 'stretch',
        maxHeight: '100%',
        // height: '100%',
        msOverflowStyle: 'none', /* for Internet Explorer, Edge */
        scrollbarWidth: 'none', /* for Firefox */
    
        '&&::-webkit-scrollbar': {
            display: 'none', /* for Chrome, Safari, and Opera */
        },
    }),
    ShipmentTitle: styled(Box)({
        display: 'flex',
        backgroundColor: '#f5f5f5',
        flex: 1,
    }),
    RocketPanel: styled(Box)({
        paddingBottom: '40px',
        marginTop: '30px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    }),
    StopsSection: styled(Box)({
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10px 15px 0',
        flexBasis: '100%',
    }),
    AdditionalInfo: styled(Box)({
        display: 'flex',
        flex: 1,
    }),
    AdditionalInfoSection: styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        '& > .info-header': {
            fontWeight: '500',
            paddingBottom: '10px',
        },
        '& > div': {
    
        },
    }),
    IconBuffer: styled(Box)({
        paddingTop: '15px',
        color: 'grey',
        fontWeight: '700'
    }),
    StopPanel: styled(Box)({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        textAlign: 'center',
        '& > .stop-body': {
            backgroundColor: 'white',
            borderTop: '2px solid #3ca9f5 ',
            display: 'flex',
            flexWrap: 'wrap',
            margin: '10px 0 0',
            flexBasis: '100%',
            padding: '10px 5px',
        },
        '& .green': {
            backgroundColor: 'lightgreen'
        },
        '& >.left-edge': {
            borderLeft: '2px solid #3ca9f5 ',
            borderRadius: '3% 0 0 0',
        },
        '& .right-edge': {
            borderRight: '2px solid #3ca9f5 ',
            borderRadius: '0 3% 0 0',
        },
    }),
    StopPanelHeader: styled(Box)({
        backgroundColor: '#ccc',
        padding: '5px',
        border: 'none',
    }),
    SpacerBox: styled(Box)({
        flexGrow: 1,
        // alignSelf: 'stretch',
        // backgroundColor: 'purple',
    }),
    ActivityPanel: styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden'
    }),
    ScrollableDiv: styled(Box)({
        overflowY: 'auto'
    }),
    Event: styled(Box)({
        padding: '10px 0',
        backgroundColor: '#ccc',
    }),
};


export const ShipmentDetailContainer = styled(Box)({
    display: 'flex',
    maxHeight: '100%',
    height: '100%',
    //paddingBottom: '15px',
});

export const SideMenu = styled(Box)({
    width: '3vh',
    //backgroundColor: 'green',
    display: 'flex',
    //height: '95.5vh',
    //overflowY: 'hidden',
    flexDirection: 'column',
    left: 0,
    alignItems: 'center',
    //padding: '10px 0',
    //justifyContent: 'center',
    justifyItems: 'stretch',
});

export const MainContent = styled(Box)({
    flex: 1,
    //border: '10px solid violet',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'start',
    alignContent: 'start',
    //backgroundColor: 'violet',
    height: '100%',
    //overflow: 'auto',
    maxHeight: '100%',
});

export const ShipmentHeader = styled(Box)({
    //flexBasis: '90%',
    display: 'flex',
    //border: '1px solid yellow',
    alignSelf: 'start',
    alignItems: 'center',
    width: '100%',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'yellow',
    // height: 'auto',
    // maxHeight: '10vh',
    //flexBasis: '100%',
});

export const ShipmentBody = styled(Box)({
    flex: 1,
    //border: '5px solid red',
    maxHeight: 'calc(100% - 5vh )',
    height: 'calc(100% - 5vh)',
    display: 'flex',
});

export const ShipmentInfo = styled(Box)({
    display: 'flex',
    //flexDirection: 'column',
    flexWrap: 'wrap',
    flex: 1,
    backgroundColor: '#e8e8e8',
    // backgroundColor: '#f5f5f5',
    overflowY: 'auto',
    //alignSelf: 'start', // stops height from stretching to height of activity panel
    alignContent: 'start',
    //justifySelf: 'start',
    //alignItems: 'start',
    maxHeight: '100%',
    msOverflowStyle: 'none', /* for Internet Explorer, Edge */
    scrollbarWidth: 'none', /* for Firefox */

    '&&::-webkit-scrollbar': {
        display: 'none', /* for Chrome, Safari, and Opera */
    },
});

export const ShipmentTitle = styled(Box)({
    display: 'flex',
    backgroundColor: 'white',
    flex: 1,
    alignItems: 'center',
    //padding: '5px 0'
});

export const RocketPanel = styled(Box)({
    paddingBottom: '40px',
    marginTop: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

export const StopsSection = styled(Box)({
    display: 'flex',
    //flexBasis: '100%',
    flexWrap: 'wrap',
    //alignContent: 'start',
    //justifyContent: 'spaced-between',
    //justifyItems: 'spaced-between',
    //border: '5px solid grey',
    margin: '10px 15px 0',
    flexBasis: '100%',
});

export const AdditionalInfo = styled(Box)({
    display: 'flex',
    flex: 1,
});

export const AdditionalInfoSection = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',

    '& > .info-header': {
        fontWeight: '500',
        paddingBottom: '10px',
    },
    '& > div': {

    },
});

export const IconBuffer = styled(Box)({
    paddingTop: '15px',
    color: 'grey',
    fontWeight: '700'
});

export const StopPanel = styled(Box)({
    display: 'flex',
    //overflow: 'auto',
    //flexWrap: 'wrap',
    flex: 1,
    flexDirection: 'column',
    //justifyItems: 'center',
    textAlign: 'center',
    '& > .stop-body': {
        backgroundColor: 'white',
        //borderBottom: '2px solid #3ca9f5 ',
        borderTop: '2px solid #3ca9f5 ',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '10px 0 0',
        flexBasis: '100%',
        padding: '10px 5px',
    },
    '& .green': {
        backgroundColor: 'lightgreen'
    },
    '& >.left-edge': {
        borderLeft: '2px solid #3ca9f5 ',
        borderRadius: '3% 0 0 0',
    },
    '& .right-edge': {
        borderRight: '2px solid #3ca9f5 ',
        borderRadius: '0 3% 0 0',
    },
});

export const StopPanelHeader = styled(Box)({
    backgroundColor: '#ccc',
    padding: '5px',
    border: 'none',
});

export const SpacerBox = styled(Box)({
    flexGrow: 1
});

export const ScrollableDiv = styled(Box)({
    //backgroundColor: 'green',
    //flex: 1,
    overflowY: 'auto'
});

export const ActivityPanelHeader = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
});

export const ActivityPanel = styled(Box)({
    //border: '3px solid blue',
    display: 'flex',
    flexDirection: 'column',
    //borderTop: '1px solid #e0e0e0',
    //borderBottom: '1px solid #e0e0e0',
    right: 0,
    //flex: 1,
    //backgroundColor: 'purple',
    overflowY: 'hidden'
});