import React, { useState, useEffect } from 'react';
import { getConfig } from './ApiService';



export function DynamicScripts({ children }) {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if ( isScriptLoaded ){
      return;
    }
    const fetchConfig = async () => {
      try {
        const configData = await getConfig();
        (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
        ({key: configData.google_maps_api_key ?? "TEMP_KEY", v: "weekly"});
        setIsScriptLoaded(true);
      } catch (error) {
        console.error("Failed to fetch config:", error);
      }
    };

    fetchConfig();
  }, [isScriptLoaded]);

  /* 
    we shouldnt have to rerender with useEffect below. 

    if (config){}... 
  */

  /*useEffect(() => {
    if (config) {
      console.log(config);
      // Google Tag Manager
       if (config.google_tag_id) {
        loadScript(`https://www.googletagmanager.com/gtag/js?id=${config.google_tag_id}`, () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() { window.dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', config.googleTagManagerId);
        });
      } 

      // Google Maps API
      if (config.google_maps_api_key) {
        //loadScript(`https://maps.googleapis.com/maps/api/js?key=${config.google_maps_api_key}&callback=initMap&v=weekly&libraries=marker`);
        // loadScript(`https://maps.googleapis.com/maps/api/js?key=${config.google_maps_api_key}&callback=initMap`);

        // const loadGoogleMapsScript = async ({ version = "weekly", libraries = "marker" }) => {
        //   (g => {
        //     var h, a, k, p = "The Google Maps JavaScript API", c = "google", l = "importLibrary", q = "__ib__", m = document, b = window;
        //     b = b[c] || (b[c] = {});
        //     var d = b.maps || (b.maps = {}), r = new Set, e = new URLSearchParams,
        //       u = () => h || (h = new Promise(async (f, n) => {
        //         await (a = m.createElement("script"));
        //         e.set("libraries", [...r] + "");
        //         for (k in g) e.set(k.replace(/[A-Z]/g, t => "_" + t[0].toLowerCase()), g[k]);
        //         e.set("callback", c + ".maps." + q);
        //         a.src = `https://maps.${c}apis.com/maps/api/js?` + e;
        //         d[q] = f;
        //         a.onerror = () => h = n(Error(p + " could not load."));
        //         a.nonce = m.querySelector("script[nonce]")?.nonce || "";
        //         m.head.append(a)
        //       }));
        //     d[l] ? console.warn(p + " only loads once. Ignoring:", g) : d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n))
        //   })({
        //     key: config.google_maps_api_key,
        //     v: "weekly",
        //     libraries: "marker"
        //   });
        // };
        
        //loadGoogleMapsScript()
      }
    }
  }, [config]);*/
  // useEffect(() => {
  //   if (config) {
  //     console.log(config);

  //     // Google Maps API
  //     if (config.google_maps_api_key) {

  //       (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
  //         ({key: config.google_maps_api_key, v: "weekly"});

  //       setIsScriptLoaded(true);
  //     }
  //   }
  // }, [config]);

  return (<>
    { isScriptLoaded ? (
        <>{ children }</>
    ) : (
        <>Loading Scripts</>
    )}
  </>);
}
