import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, MenuItem, Menu, IconButton, Box} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { AccountBoxSharp } from '@mui/icons-material';

export function Header(){
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileView, setMobileView] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(){
    setAnchorEl(null);
  };

  const navigation = useNavigate();

  const handleResize = () => {
    if (window.innerWidth < 960) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

	function onMouseDown(event, nav, args){
		if (event.button === 0){
			// event.preventDefault();
      
      handleClose();
			event.preventDefault();
			// document.querySelector('#app-container').scrollTo(0, 0)
			navigation(nav, {behavior: 'smooth' , ...args})
		}
	}

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);
  


  return (
    <>

      <AppBar  position="fixed" elevation={0}>
        <Toolbar className='app-bar' sx={{backgroundColor: '#FFF', }}>
        {/* <Toolbar className='app-bar' sx={{backgroundColor: '#4285F4'}}> */}

        <Box className='background-logo' onMouseDown={() => navigation('', { state: {destination: 'home'}})}  justifyContent='start'> </Box>
        <Typography fontWeight={1000} fontSize={24} variant='body2' style={{flexGrow:1}} onClick={() => navigation('/')}> <span className="logo"></span>
        </Typography>
          {!mobileView ? (
            <>
              {/* This part will show on large screens */}
              <Button color="inherit" size='small' onMouseDown={(e) => onMouseDown(e,'/technology')}>Technology</Button>
              <Button color="inherit" size='small' onMouseDown={(e) => onMouseDown(e,'/shippers')}>Shippers</Button>
              {/* ... Other buttons */}
              <Button color="inherit" size='small' onMouseDown={(e) => onMouseDown(e,'/carriers')}>Carriers</Button>
              <Button color="inherit" size='small' onMouseDown={(e) => onMouseDown(e,'/', {state: {destination: 'why'}})}>Why Photon</Button>
              <Button color="inherit" size='small' onMouseDown={(e) => onMouseDown(e,'/company')}>Company</Button>
              <Button color="inherit"  size='small' onMouseDown={(e) => onMouseDown(e,'/login')}>Login</Button>
              <Button color='inherit'  size='small' variant='outlined' sx={{backgroundColor: '#512aeb', color: 'white'}} onMouseDown={(e) => onMouseDown(e,'/',  {state: {destination: 'demo'}})}>
                 Demo
              </Button>
              {/* <Button color='inherit'  size='small' variant='outlined'  sx={{backgroundColor: '#512aeb', color: 'white'}} onMouseDown={(e) => onMouseDown(e,'/quote')}>
                 Quote
              </Button> */}
            </>
          ) : (
            <>
              {/* This part will show on small screens */}
              <Button className='oval-button' size='small'  variant='outlined' sx={{color: '#512aeb', border: '1px solid #512aeb'}}  onMouseDown={(e) => onMouseDown(e,'/',  {state: {destination: 'demo'}})}>
                Demo
              </Button>
              <IconButton edge="end" color="inherit" aria-label="menu" onMouseDown={(e) => handleClick(e)}>
                <MenuIcon />
              </IconButton>
            </>
          )}

          {/* Your original dropdown menu */}
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onMouseDown={(e) => onMouseDown(e,'/login')}>Login</MenuItem>
            {/* ... Other buttons */}
            <MenuItem onMouseDown={(e) => onMouseDown(e,'/shippers')}>Shippers</MenuItem>
            <MenuItem onMouseDown={(e) => onMouseDown(e,'/carriers')}>Carriers</MenuItem>
            <MenuItem onMouseDown={(e) => onMouseDown(e,'/technology')}>Technology</MenuItem>
            <MenuItem onMouseDown={(e) => onMouseDown(e,'/', { state: { destination: 'why'}})}>Why Photon</MenuItem>
            <MenuItem onMouseDown={(e) => onMouseDown(e,'/company')}>Company</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
