import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Grid, Paper, Box, Typography, Container, Divider, Link } from '@mui/material';
import { UserLogin } from '../../services/ApiService'
import { apiClient } from "../../services/api";
import { AuthContext } from '../../contexts/AuthContext';

export function Login() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [didForgotPassword, setDidForgetPassword] = useState(false);
	const navigate = useNavigate();

	// TODO: FAILS currently because component not wrapped by AuthProvider
	const { login, logout } = useContext(AuthContext) || {};


	const handleSubmitt = async (event) => {
		event.preventDefault();
		// try {
		//   const response = await UserLogin(username, password); // Pass both username and password to match your Django view

		//   if (response.message === 'Logged in successfully.') {
		//     navigate('/dashboard');
		//     window.location.reload();
		//   } else {
		//     alert('Invalid username or password');
		//   }

		//   } catch (error) {
		//     console.error('An error occurred during login:', error);
		//     alert('An error occurred. Please try again.');
		//   }
		navigate('/dashboard/shipments');
		//window.location.reload();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		/*       login(username, password).then(data => {
				console.log('Logged in!', data);
				  // Redirect or perform actions after successful login
				navigate('/dashboard/shipments');
		
			  }).catch(error => {
				console.error('Login failed!', error);
			  }); */

		//login(username, password);
		// navigate('/dashboard/shipments');

	};

	function onKeyDown(event){
		if (event.key ==='Enter'){
			event.preventDefault();
			authenticate();
		}
	};

	function onMouseDown(event){
		if (event.button === 0){
			event.preventDefault();
			authenticate();
		}
	}

	function authenticate(){
			/*       login(username, password).then(data => {
					console.log('Logged in!', data);
					// Redirect or perform actions after successful login
					navigate('/dashboard/shipments');
			
				}).catch(error => {
					console.error('Login failed!', error);
				}); */

			//login(username, password);
			navigate('/dashboard/shipments');
	}

	function forgotPassword(event){
		if (event.button === 0){
			event.preventDefault();
			setDidForgetPassword(!didForgotPassword)
			console.log('forgot password')
		}
	};

	return (
		// <Grid sx={{ m: 0, }} container spacing={3} justify="center" direction="row">
		// 	<Grid item>
		// 		<Grid container direction="column" justify="center" spacing={2} className="login-form">
		// 			<Paper variant="elevation" elevation={2} className="login-background">
		// 				<Grid item>
		// 					<Typography component="h1" variant="h5">
		// 						Sign in
		// 					</Typography>
		// 				</Grid>
		// 				<Grid item>
		// 					<form onSubmit={handleSubmit}>
		// 						<Grid container direction="column" spacing={2}>
		// 							<Grid item>
		// 								<TextField type="text" placeholder="Username" fullWidth name="username" variant="outlined"
		// 									onChange={(event) => setUsername(event.target.value)} />
		// 							</Grid>
		// 							<Grid item>
		// 								<TextField type="password" placeholder="Password" fullWidth name="password" variant="outlined"
		// 									onChange={(event) => setPassword(event.target.value)} />
		// 							</Grid>
		// 							<Grid item>
		// 								<Button variant="contained" color="primary" type="submit" className="button-block">Submit</Button>
		// 							</Grid>
		// 						</Grid>
		// 					</form>
		// 				</Grid>
		// 			</Paper>
		// 		</Grid>
		// 	</Grid>
		// </Grid>
		<Paper sx={{backgroundColor: '', width: '360px', margin: 'auto', p: '1em', }} onKeyDown={onKeyDown} elevation={0}>
			<form onSubmit={handleSubmit}>
				<Typography variant="h6" align="center">Sign In</Typography>
				{/* <Divider orientation="horizontal" sx={{ my: '.5em', }} flexItem /> */}
				{/* <Typography variant="body1" gutterBottom>email</Typography> */}
				<Box height='1em'></Box>
				<TextField
						fullWidth
						// name="username" 
						variant="outlined"
						size='small'
						// id="outlined-textarea"
						label="Username"
						placeholder="enter user name ..."
						// value={input}
						onChange={(event) => setUsername(event.target.value)}
						// onKeyDown={onKeyDown}
				/>
				{/* <Typography variant="body1" gutterBottom>password</Typography> */}
				<Box height='1em'></Box>
				<TextField
						mt='1em' 
						fullWidth
						name="password" 
						variant="outlined"
						size='small'
						label='Password'
						placeholder="enter password ..."
						type='password'
						onChange={(event) => setPassword(event.target.value)}
				/>
				<Button sx={{mt:'1em', backgroundColor:"#512aeb"}} variant="contained"  fullWidth onMouseDown={onMouseDown}  type="submit" className="button-block">Submit</Button>
			</form>
			<Box height='1em'></Box>
			<Link onMouseDown={forgotPassword} underline="hover" align="center" style={{ cursor: 'pointer', }} component='div'>Forgot Password?</Link>
			<Box height='2em'></Box>
			{ didForgotPassword && <Typography variant="body1" color='red'>Reach out to your Photon representative to resolve login issues.</Typography>}
		</Paper>
	);
};


// auth.js
/* const login = async (username, password) => {
  const response = await apiClient.post('/token', { username, password });
  localStorage.setItem('accessToken', response.data.access);
  localStorage.setItem('refreshToken', response.data.refresh);
  localStorage.setItem('userName', username);
  //apiClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;

  return response.data;
}

const setLocalStorage = async (data) => {
  const {access, refresh, userName} = data;

  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
  localStorage.setItem('userName', userName);

  return access;
}

// Function to be called when an access token needs to be refreshed
const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
	try {
	  const response = await apiClient.post('/token/refresh', { refresh: refreshToken });
	  
	  //localStorage.setItem('accessToken', response.data.access);


	  // return localStorage.getItem('accessToken'); 
	  var token = await setLocalStorage(response.data);
	  return token;
	} catch (error) {
	  console.error('Error refreshing access token:', error);
	  // Handle error - for example, redirect to login if refresh fails
	}
  } else {
	// No refresh token available, force logout or redirect to login
  }
};

apiClient.interceptors.response.use(response => response,  async (error) => {
  const originalRequest = error.config;
  let result = Promise.reject(error);
  
  if (error.response.status === 401 && originalRequest._retry) { 
	// Redirect to login or do something else when refresh token also fails
	result = Promise.reject(error);

  } else if (error.response.status === 401) {  
	originalRequest._retry = true; // Marking so we know this request has already tried to retry
	const accessToken = await refreshToken();
    
	if (accessToken) {
	  // Update the header and return the original request
	  //apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
	  result = apiClient(originalRequest);
	}
  }

  return result;
});

// Inject the JWT token into headers of each request if available
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token) {
	  config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
 */
/* 
apiClient.interceptors.response.use(
  response => response,
  async (error) => {
	  const originalRequest = error.config;
	  if (error.response.status === 401 && originalRequest.url === '/api/token/refresh/') {
		  // Redirect to login or do something else when refresh token also fails
		  return Promise.reject(error);
	  }

	  if (error.response.status === 401 && !originalRequest._retry) {
		  originalRequest._retry = true;
		  const refresh_token = localStorage.getItem('refresh_token');
		  const response = await api.post('/api/token/refresh/', { refresh: refresh_token });
		  localStorage.setItem('access_token', response.data.access);
		  api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
		  return api(originalRequest);
	  }

	  return Promise.reject(error);
  }
);
*/
