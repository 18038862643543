import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material'

export function Company() {
    return (
        <Box>
            <Box backgroundColor='#512aeb' color='white' p='4em 1em' margin='auto' display='flex' flexDirection='column' alignItems='center'>
                <Typography maxWidth='500px' align='center' variant='h4' paragraph><strong>A 3PL provider implementing software to digitize supply chains</strong></Typography>
                <Typography maxWidth='600px' align='center' variant='h5'>
                    Our logistics platform uses modern technology to streamline freight operations. Increase productivity while saving time, money, and energy.
                </Typography>

            </Box>
            <Box p='2em .5em' display='flex' flexDirection='column' alignItems='center' maxWidth='1000px' margin='auto'>
                <Typography align='left' variant='h6' paragraph>
                    At Photon Freight, we revolutionize the supply chain industry. We integrate cutting-edge technology to transform traditional freight operations into seamless digital experiences.
                </Typography>

                <Typography align='left' variant='h6' paragraph>
                    Our mission is simple: leverage software to provide modern, advanced logistics solutions to enhance efficiency and optimize costs for businesses worldwide. Utilizing real-time tracking, automated route planning, and data analytics, we ensure your freight reaches its destination swiftly and securely.
                </Typography>

                <Typography align='left' variant='h6' paragraph>
                    Why choose us? Photon Freight stands at the forefront of innovation, constantly evolving to meet the demands of modern commerce. Our team of experts is dedicated to providing unparalleled service, making us the preferred partner for businesses aiming to stay ahead in a competitive market.
                </Typography>

                <Typography align='left' variant='h6' paragraph>
                    Discover the future of logistics with Photon Freight – where technology and efficiency converge to deliver exceptional results.
                </Typography>
            </Box>
        </Box>
    );
}