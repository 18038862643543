import axios from 'axios';

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = 'csrftoken';

const API_URL = process.env.REACT_APP_API_URL;
const INTERNAL_URL = process.env.REACT_APP_INTERNAL_URL;

const GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api';
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const cookieee = getCookie('csrftoken');

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    //'X-CSRFToken': cookieee,
  },
  
  credentials: 'cross-origin'
});

export const internalClient = axios.create({
  baseURL: INTERNAL_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': cookieee,
  },
  credentials: 'cross-origin'
});

export const googleMapsClient = axios.create({
  baseURL: GOOGLE_MAPS_API_URL,
  data: {
    'content-type': 'application/json',
  },
});