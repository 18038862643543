import React, { useEffect, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import { Button, Box, } from '@mui/material';

const trip_id = 1;
const shipment_id = 1; // ios prod
// const shipment_id = 2; // android

export function createSocketConnection ({ url }){
	let messageHandlers = {};
	let socket = null;

	function connect(){
		socket = new WebSocket(url);
		 console.log("LOOKING FOR ANEW CONNECT:", socket)

		socket.onopen = (e) => console.log('NEW CONNECT BIH\n', socket);
		socket.onerror = (e) => {
			console.log('WebSocket Error:',  e)
		};
		socket.onclose = (e) => console.log('WebSocket Disconnected\n', socket);

		socket.onmessage = (e) => {
			const data = JSON.parse(e.data);
			console.log("NEW CONNECT ON THE PHONE", data)
			console.log(data.data2)

			const handler = messageHandlers[data.type]
			return handler?.(e) || [];
		};

		// Disconnect WebSocket when component unmounts
		return () => {

			try {
				if (socket) {
			console.log('unmountinggggggg',  socket)
					if (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING) {
						console.log('*********** unmountinggggggg',  socket)
						socket?.close();
					}
					socket = null;
					messageHandlers = {};
				}
			} catch (e) { console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@")}
		};
	}

	const sendMessage = (message) => {
		// const sender = 6; // android
		const sender = 1; // ios prod

		const data = {
			data: { ...message, sender, trip_id, shipment_id },
			time_sent: new Date(),
			type: 'chat.message',
		};

		if (socket && socket.readyState === WebSocket.OPEN) {
			socket.send(JSON.stringify(data));
			console.log('Message sent:', JSON.stringify(data));
		}
	};

	return {
		connect,
		disconnect() {
			console.log("Disconnecting Socket")
			socket.close();
			console.log("Successfully Disconnected Socket")
		},
		on(event, callback) {
			if (socket) {
				if (messageHandlers.event) {
					throw Error("Event is already registered as handler.");
				}

				messageHandlers[event] = callback;

				return () => {
					delete messageHandlers[event]
				}
			}
		},
		sendMessage,
		socket,
		messageHandlers,
		getUrl() {
			return url;
		},
	};
};

export const useSocketConnection = () => {
	// TODO: Initially used ref but decided state is better. Consumer should have option to render on state update
	const connectionRef = useRef(null);
	connectionRef.current = createSocketConnection();
	let cleanup;

	useEffect(() => {

		//console.log("USE SOCKET CONNECTION: \n", connectionRef.current);
		try {
			
			console.log('attempt connecting to ...')
			cleanup = connectionRef.current.connect();
		} catch (error) {
			console.log("hoeeee")
		}

		return () => {
			cleanup?.();
		};
	}, []);

	return connectionRef;
};

export function ChatRoom({ user, connection, tripId, shipmentId }){
	const [input, setInput] = useState('')

	function handleKeyDown(event){

		if (event.key ==='Enter' && input.length > 0){
			event.preventDefault();
	
			connection.sendMessage({
				message: input,
				tripId,
				shipmentId,
			});
			setInput('');
		}
	};

	return (
		<Box
			sx={{backgroundColor: '#fafafa', p:'.3em 1em .6em',}}
		>
				<TextField
					id="outlined-textarea"
					label="send message ..."
					placeholder="Send Message"
					multiline
					fullWidth
					value={input}
					InputProps={{ sx: { borderRadius: '3em', backgroundColor: 'white', height: '2em' } }}

					onKeyDown={handleKeyDown}
					size='small'
					onChange={event => setInput(event.target.value)}
					autoComplete="off"
				/>
		</Box>
	);
}
