import { createContext } from "react";
import React, { useState, useEffect} from "react";
import { userSignOut } from '../services/ApiService'
import { apiClient } from "../services/api";

export const AuthContext = createContext(null);

export function AuthProvider({ children }){
    const [user, setUser] = useState(null);

    // useEffect(() => {

    //   const storedUser = localStorage.getItem('user');
    //   if (storedUser) {
    //     setUser(JSON.parse(storedUser));
    //   } else {
    //     setUser(JSON.parse({
    //       'first_name': 'Duevyn',
    //       last_name: 'Cooke',
    //       // id: 6, //android
    //       id: 1,
    //       username: 'Duevyn', 
    //       company: "Tmg"
    //     }))
    //   }

    //   return () => { }
    // }, []);
  
    async function login( username, password){
      try {
          const response = await apiClient.post('/token', { username, password }).data;

          const { access, refresh } = response.data;
          localStorage.setItem('accessToken', access);
          localStorage.setItem('refreshToken', refresh);
          localStorage.setItem('userName', username); // replaced with user. for backwards compat. remove

          const user = (await apiClient.get("/user_profile", { params: { username } })).data
          localStorage.setItem('user', JSON.stringify(user));
          setUser(user);

	        const data = {...response, username} // needs to be updated after setting user
      	  return data;
      } catch (e) {
        throw e;
      }
   }

    async function logout(){
      setUser(null);
      localStorage.clear();
      userSignOut();
    }

    return(
      <AuthContext.Provider value={{ user, login, logout }}>
          { children }
      </AuthContext.Provider>
  );
}
  
  // Function to be called when an access token needs to be refreshed
  const refreshToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      try {
        const response = await apiClient.post('/token/refresh', { refresh: refreshToken });
        const { access } =  response.data 
        
        localStorage.setItem('accessToken', access);
  

        // return localStorage.getItem('accessToken'); 
        // var token = await setLocalStorage(response.data);
        return access;
      } catch (error) {
        console.error('Error refreshing access token:', error);
        // Handle error - for example, redirect to login if refresh fails
      }
    } else {
      // No refresh token available, force logout or redirect to login
        console.log("Refresh token not available")
    }
  };
  
  apiClient.interceptors.response.use(response => response,  async (error) => {
    const originalRequest = error.config;
    let result = Promise.reject(error);
    
    if (error.response.status === 401 && originalRequest._retry) { 
      // TODO: Redirect to login or do something else when refresh token also fails
      // TODO: FAIL in testing. cleared local storage manually. implementing logout will help mitigate.
      result = Promise.reject(error);
  
    } else if (error.response.status === 401) {  
      originalRequest._retry = true; // Marking so we know this request has already tried to retry
      const accessToken = await refreshToken();
      
      if (accessToken) {
        // Update the header and return the original request
        //apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
        result = apiClient(originalRequest);
      }
    }
  
    return result;
  });
  
  // Inject the JWT token into headers of each request if available
  apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
  
