import { apiClient, internalClient } from './api';

// deprecated for  /token route. 
export const UserLogin = async ( username, password ) => {
  try {
    const response = await internalClient.post(`/login`, {username, password});
    return response.data;
  } catch (error) {
    console.error('Error with Login:', error);
    throw error;
  }
};

export const listAndCreateRequestedShipments = async (userId) => {
  try {
    const response = await apiClient.get(`/users/${userId}/shipments/sent`);
    return response.data;
  } catch (error) {
    console.error('Error fetching received business requests:', error);
  }
};

export const getShipments = async ( query_params ) => {
  //const {requested_by, sent_by, action} = query_params;
  const params = query_params;

  try {
    const response = await apiClient.get('/shipments', { params })
    if (response){

    console.log('PARAM: ', response.data);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching Shipments:', error);
  }
};

export const getTrips = async (query_params) => {
  const { requested_by, sent_by, action } = query_params;

  try {
    const response = await apiClient.get('/trips', {query_params})
  } catch (error) {
    console.error('Error fetching Trips', error);
  }
};

export const getStops = async (trip_id) => {

  try {
    const response = await apiClient.get(`/trips/${trip_id}/stops`)
    console.log("GET STOP DATA", response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching Stops', error);
  }
};

export const getEvents = async (query_params) => {
  const params = query_params;

  try {
    const response = await apiClient.get('/events', { params })
    return response.data;
  } catch (error) {
    //({ error, filename: "Api.js", functionCalled: $`getEvents(${query_params})`})
    console.error('Error fetching Events', error);
  }
};

export const getGps = async (query_params) => {
  const params = query_params;

  try {
    const response = await apiClient.get('/gps', { params })
    console.log("GET gps DATA", response)
    return response.data;
  } catch (error) {
    //emitError({ error, filename: "Api.js", functionCalled: $`getGps(${query_params})`})
    console.error('Error fetching Stops', error);
  }
};

export const getConfig = async () => {
  try {
    const response = await internalClient.get(`/config`);
    return response.data;
  } catch (error) {
    console.error('Error fetching config:', error);
  }
};

export const userSignOut = async () => {
  try {
    const response = await apiClient.post( `/logout` );
    return response.data;
  } catch (error){
    console.log("error signing out", error);
  }
}

export const postDemo = async (demoData) => {
  try {
    const response = await apiClient.post('/demos', demoData);
    return response.data;
  } catch (error) {
    console.error('Error posting Demo', error);
  }
};

// Add more functions for other API calls