import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab, Box, Divider, Typography, Button, IconButton, Container } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useNavigate, useLocation, useParams, useNavigation, Outlet, useLoaderData } from "react-router-dom";
import './Dashboard.css'
import { styled } from '@mui/system';

export function ActivityPanel({ events, drivers, info}){
    const [activityPanelSwitch, setActivityPanelSwitch] = useState(0);
console.log(drivers)
    const scrollToRef = useRef();
    const ScrollableDiv = styled(Box)({
        backgroundColor: '#FAFAFA',
        // backgroundColor: 'green',
        //flex: 1,
        overflowY: 'auto'
    });

    const user = { id: 1, company: 1 }
    console.log('eeeeeeee', events.slice())

    console.log('info', info)
    const ActivityPanelHeader = styled(Box)({
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: 'purple',
        alignItems: 'right',
    });

    const ActivityPanel = styled(Box)({
        //border: '3px solid blue',
        display: 'flex',
        flexDirection: 'column',
        //borderTop: '1px solid #e0e0e0',
        //borderBottom: '1px solid #e0e0e0',
        right: 0,
        //flex: 1,
        backgroundColor: '#fafafa',
        overflowY: 'hidden'
    });

    const rows = [];
    let lastSender = null;

    const HOUR = 60 * 60 * 1000;
    const TEN_MINS = 10 * 60 * 1000;

    const dayBuckets = new Map();


    let lastDay;
    let lastTime;
    for (let i = events.length - 1; i >= 0; i--){
        let event = events[i];
        const eventDay = new Date(event.timestamp).toLocaleDateString();
        const isUserMessage = !!event.message_text;
        let shouldHideTime = false;
        
        if (lastDay !== eventDay) {
            lastDay = eventDay;
            lastTime = event.timestamp;
            rows.push(<EventHeaderRow timestamp={new Date(event.timestamp).toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })} key={eventDay} align='center'></EventHeaderRow>)
        } else {
            shouldHideTime = isUserMessage && lastTime && ((new Date(event.timestamp) - new Date(lastTime)) < HOUR);
            if (!shouldHideTime){
                const lastTimeString = new Date(event.timestamp).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                rows.push(<Typography variant='body2' key={event.timestamp} textAlign={'center'} fontSize={11} fontWeight={600} sx={{ mt: '5px' }}>{lastTimeString}</Typography>)
            }
        }

        let align = 'center';
        let className = 'system'
        if (isUserMessage) {
            align = "left";
            className = 'message'
        
            // className = drivers[event.triggered_by.id] ?  'message driver' : 'message';
            if (drivers[event.triggered_by.id]){
                className = 'message driver'
            }
            else if (event.triggered_by.id === user.id){
                align = 'right';
            }
            else if (event.triggered_by.company === user.company) {
              // leave gray so teamembers will match
              className = 'message team'
            }
            else if (event.triggered_by.company ===  info.consignee ){
                className = 'message consignee'
            }
            else if (event.triggered_by.company ===  info.consignor ){
                className = 'message consignor'
            }
            else if (event.triggered_by.company ===  info.broker ){
                className = 'message broker'
            }
            else if (event.triggered_by.company ===  info.broker ){
                className = 'message carrier'
            }
            if (event.triggered_by.id !== lastSender?.id) {
                const name = event?.triggered_by.first_name + ' ' + event?.triggered_by?.last_name
                let comp = <Box display='flex' key={`${event.id}-${i}`} justifyContent={align} alignItems='end'>
                    <Typography variant='body2'  textAlign={align} fontSize={14} fontWeight={600} sx={{ mt: '5px' }}>{name}</Typography>
                        <Typography sx={{backgroundColor: ''}} key={`${i}-${event.id}`} color='gray' textAlign={align} fontSize={11} fontWeight={500}> &#160; | {event?.triggered_by.company_name}</Typography>
                    </Box>
                rows.push(comp)
            }
            lastSender = event.triggered_by;
        }

        rows.push(<EventRow event={event} key={event.id} align={align} className={className} lastTime={lastTime} hideTime={shouldHideTime}></EventRow>);
        lastTime = event.timestamp; 
    }

    // for (let i = events.length - 1; i >= 0; i--){
    //     const eventDay = new Date(events[i].timestamp).toLocaleDateString();
    //     if (!dayBuckets.has(eventDay)) {
    //         dayBuckets.set(eventDay, []);
    //     }
    //     dayBuckets.get(eventDay).push(events[i]);     
    // }

    // events.reverse().forEach((event) => {
    // events.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)).forEach((event) => {
    //     const eventDay = new Date(event.timestamp).toLocaleDateString();
    //     if (!dayBuckets.has(eventDay)) {
    //         dayBuckets.set(eventDay, []);
    //     }
    //     dayBuckets.get(eventDay).push(event);
    // });

    // for (const [day, dayEvents] of dayBuckets) {
    //     // console.log(`Events for ${day}:`, dayEvents);
    //     rows.push(<EventHeaderRow timestamp={new Date(dayBuckets.get(day)[0].timestamp).toLocaleString('en-US', { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })} key={day} align='center'></EventHeaderRow>)

    //     // lastTime = null;
    //     let lastTime = dayBuckets.get(day)[0].timestamp;
    //     let align = 'center';
    //     let className = 'system'
    //     dayEvents.forEach((event, i) => {

    //         let shouldPostTime = !event.message_text || !lastTime || ((new Date(event.timestamp) - new Date(lastTime)) > HOUR);
    //         const skippedFirst = i > 0;
            
    //         if (shouldPostTime && skippedFirst) { //ignore posting time if first message of day since it is posted w/ header
    
    //             const lastTimeString = new Date(event.timestamp).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                
    //             rows.push(<Typography variant='body2' key={event.timestamp} textAlign={'center'} fontSize={11} fontWeight={600} sx={{ mt: '5px' }}>{lastTimeString}</Typography>)
    //         }

    //         // we want to isolate user messages from sys events. Data is corrupted now though, so named boolean to remember 
    //         const isUserMessage = !!event.message_text;
    //         if (isUserMessage) {
    //             // day === "2/25/2024" && console.log(`i: ${i}, event: ${shouldPostTime}, last date: ${new Date(lastTime).toLocaleTimeString()}, new date: ${ new Date(event.timestamp).toLocaleTimeString()}`)
    //             align = event.triggered_by.id === user.id ? "right" : "left";

                
    //             className = drivers[event.triggered_by.id] ?  'message driver' : 'message';
    //             if (event.triggered_by.id !== lastSender?.id) {
    //                 const name = event?.triggered_by.first_name + ' ' + event?.triggered_by?.last_name
    //                 let comp = <Box display='flex' justifyContent={align} alignItems='end'>
    //                     <Typography variant='body2' key={`${event.id}-${i}`} textAlign={align} fontSize={14} fontWeight={600} sx={{ mt: '5px' }}>{name}</Typography>
    //                         <Typography sx={{backgroundColor: ''}} key={`${i}-${event.id}`} color='gray' textAlign={align} fontSize={11} fontWeight={500}> &#160; | {event?.triggered_by.company_name}</Typography>
    //                     </Box>
    //                 rows.push(comp)
    //             }
    //             lastSender = event.triggered_by;
    //         }
    //         const shouldHideTime = !shouldPostTime && skippedFirst // ignore hiding time of first because it is posted  
    //         // const shouldHideTime = !shouldPostTime 
    //         rows.push(<EventRow event={event} key={event.id} align={align} className={className} lastTime={lastTime} hideTime={shouldHideTime}></EventRow>);
    //         lastTime = event.timestamp;
    //     });
    // }

    rows.push(<Box ref={scrollToRef} key='srollToRef' sx={{height: '2em'}}></Box>)

    useEffect(() => {
        // Scrolls to the bottom every time the messages list is updated
        // if (!events || events.length == 0){
        //     return;
        // }


        if (events?.length > 0) {
            scrollToRef.current?.scrollIntoView(true);
        }
    }, [events]);

    return (
        <ActivityPanel>
            <ActivityPanelHeader>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', }}>
                    <Tabs value={activityPanelSwitch} sx={{ fontSize: '20px', backgroundColor: '', textAlign: 'right', }} onChange={() => { }}>
                        {/* <Tabs value={activityPanelSwitch} sx={{ fontSize: '20px', backgroundColor: 'lightseagreen'}} onChange={() => {}}> */}
                        <Tab label='Activity' ></Tab>
                        <Tab label='Who' ></Tab>
                        <Tab label='AI' ></Tab>
                    </Tabs>
                </Box>
            </ActivityPanelHeader>
            
            {/* <Divider sx={{ mb: 2, mt: 2 }} /> */}
            <ScrollableDiv id='scrollable-div' >
                {rows}
            </ScrollableDiv>
        </ActivityPanel>
    );
}

const EventRow = ({ event, align, className, lastTime, hideTime }) => {
    const Event = styled(Box)({
        //margin: '0 0 10px',
        padding: '10px 0',
        // backgroundColor: '#ccc',
        // backgroundColor:'green',
        //backgroundColor: '#F9F9F9',

        '&  .message': {
            //backgroundColor: '#ccc',
            // borderRadius: '35%',
            // padding: '1.5em',
        },


        '&:hover .invisible': {
            display: 'inline',
            cursor: 'pointer',
            // textAlign: align === "right" ? "left" : "right",
            // flex: '1', 
        },

        // className: hideTime ? 'event-hide' :  'event',

        textAlign: align,
        justifyContent: align,
    });

    let data = event.details;
    let variant = 'caption'

    if (event.message_text) {
        data = event.gpt_message ? event.gpt_message : event.message_text;

        variant = 'body2'
        // flexSrink = 1;
    }

    const content = <Box key={event.id} className={className} alignItems={align} display='flex' flexDirection='column'>

        <Typography sx={{}} textAlign='left' variant={variant}>{data}</Typography>
    </Box>;

    let display = [content];
    if (hideTime) {
        const hidden = <Typography
            className='invisible'
            display={'none'}
            sx={{}}
            fontWeight={500}
            variant='caption'
            key={event.id + 'hideTime'}
            component='div'
            fontSize={11}>
            {new Date(event.timestamp).toLocaleTimeString('en-US',)}
        </Typography>;

        const buff = <Box flex={1} key={event.id + 'buffer'}></Box>

        if (align === 'right') {
            display = [hidden, buff, content]
        } else {
            display = [content, buff,  hidden]
        }
    }

    return (
        <Event className={hideTime ? 'event-hide' : 'event'}>
        {/* <Event className={hideTime ? 'event-hide' : 'event'}> */}
            <Box display='flex' flexDirection='row' flex={1} justifyContent={align} alignItems='center' alignContent='space-between'>
                {/* {hideTime &&
                    <Typography
                        className='invisible'
                        align='left'
                        display={'none'}
                        sx={{}}
                        fontWeight={500}
                        variant='caption'
                        alignItems={align}
                        component='div'
                        fontSize={11}>
                        {new Date(event.timestamp).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                    </Typography>
                }
                <Box className={className} alignItems={align} justifyContent={align} display='flex' flexDirection='column'>
                    <Typography sx={{}} textAlign='center' variant={variant}>{data}</Typography>
                </Box> */}
                {display}
            </Box>
        </Event>
    );
}

const EventHeaderRow = ({ timestamp, senderName, align }) => {
    const dataa = senderName ? senderName : timestamp;
    let data = senderName;
    let weight = 600;

    if (!data) {
        data = timestamp;
        weight = 900;
    }

    return (
        <Typography variant='body2' textAlign={align} fontSize={12} fontWeight={weight} sx={{ mt: '5px' }}>{data}</Typography>
    );
}

export default ActivityPanel;