import React, {useEffect, useState} from 'react';
import { AppRoutes } from './components/AppRoutes/AppRoutes';
import { Dashboard } from './components/Dashboard/Dashboard';
import { BrowserRouter as Router, Route, Routes, Link, Outlet } from 'react-router-dom';
import { Container, Typography, Button, Box, Grid, Paper, Divider, TextField } from '@mui/material';
import { Header } from './components/Header/Header';
import { Home } from './components/Home/Home';
import { Quote } from './components/Quote/Quote';
import  { Login } from './components/Login/Login';
import '../src/components/AppRoutes/AppRoutes.css'
import { DynamicScripts } from  './services/DynamicScripts';
//import '../src/App.css';


export const AppLayout = () => {
  const [banner, setBanner] = useState(false)
  
  return (
    <div id='app-container' className='app-container'>
      {/* Red Banner and other components ... */}
        {/* Red Banner */}
      { banner && <div className="red-banner">
        <Link to="/quote" style={{ textDecoration: 'none', color: 'white' }}>
          <em>Gain access to our supply chain solutions integration platform FREE! Request quote now to find out more.</em>
        </Link>
      </div>}
      <Header sx={{backgroundColor:'red'}} ></Header>
      <Box id='content' className='app-content'>
        <AppRoutes ></AppRoutes>
      </Box>
      {/* Footer and other components ... */}
      <div className="footer">
        <p>© 2024 Photon Logistics. All Rights Reserved.</p>
      </div> 

    </div>
  );
};

export const App = () => {

  return (
    <Router>
      <div className="App">
        <DynamicScripts >
        <Routes>
          <Route path="*" element={<AppLayout />} />
          <Route path="/dashboard/*" element={<Dashboard/>} />
        </Routes>
        </ DynamicScripts>
      </div>
    </Router>
  );
}
