import * as React from "react";
// import {useEffect, useState} from "react";

import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useRouteError } from "react-router-dom";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import { Dashboard } from "./components/Dashboard/Dashboard";
import { DynamicScripts } from "./services/DynamicScripts";
// import * as Dash from "./components/Dashboard"
import { AppLayout } from "./App";
import { useNavigate, useLocation, useParams, useNavigation } from "react-router-dom";
import { ShipmentInfo } from './components/Dashboard/ShipmentDetails';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const router = createBrowserRouter([
  {
    path: "*",
    element: (
        <AppLayout >
        </AppLayout>
    ),
    errorElement: <ErrorPage/>,
    // children: [
    //   {
    //     path: "technology",
    //     element: Pages.Technology,
    //   },
    // ],
  },
  {
    path: "dashboard",
    element: (
      <DynamicScripts>
        <Dashboard />
      </DynamicScripts>
    ),
    //loader,
    children: [
      {
        path: "shipments",
        element: <Shipments />,
      },
      {
        path: "shipments/:tracking",
        element: <ShipmentInfo />,
      },

    ],
    errorElement: <ErrorPage/>
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// TODO: implement loader in future. ok now b/c we are avoiding fetch chain:
// https://reactrouter.com/en/main/start/tutorial


// export async function loader(){
//   const result = await getShipments({ 'managed_by_id': 1 }); // ios prod
//   const shipment = result.pop();
//   const res = { shipment, events: shipment.events}
//   console.log("############## loader", res)
//   return res;
// };

function Shipments(){
  return(
    <div>
      Shipments
    </div>
  );
};

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}