import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Home } from '../Home/Home';
import { Quote } from '../Quote/Quote';
import { Dashboard } from '../Dashboard/Dashboard';
import { Company } from '../Company/Company';
import { Login } from '../Login/Login';
import { Container, Typography, Box, Grid, Card, CardContent, Paper, List, ListItem, ListItemText, Divider, TextField, Button } from '@mui/material';
import { Shippers, } from '../Shippers/Shippers'
import { Carriers, } from '../Carriers/Carriers'
import { Technology } from '../Technology/Technology';
import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation, useParams, useNavigation } from "react-router-dom";
import './AppRoutes.css'
// import { Login } from '@mui/icons-material';
export function ScrollToTop(){
	const { pathname } = useLocation();

	useEffect(()=>{
		console.log('pathname from approutes', pathname)
		document.querySelector('#app-container').scrollTo(0, 0)
		// window.scrollTo(0,0)
	}, [pathname]);

	return null;
};

export function AppRoutes(){

	return (
		<>
		<ScrollToTop></ScrollToTop>
		<Routes>
			<Route exact  path="" element={<Home />} />
			<Route exact path="technology" element={<Technology />} />
			<Route path="shippers" element={<Shippers />} />
			<Route path="carriers" element={<Carriers />} />
			<Route path="company" element={<Company />} />
			<Route path="login" element={<Login />} />
			<Route path="quote" element={<Quote />} />
			{/* ... other routes ... */}
		</Routes>
		</>
	);
};
