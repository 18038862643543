import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Button, Box, Grid, Paper, Divider, Link, TextField } from '@mui/material';
import './Home.css'
import { useNavigate, useLocation, useParams, useNavigation } from "react-router-dom";
import { getShipments } from '../../services/ApiService';
import { QuoteForm } from '../Quote/QuoteForm';


import network from '../../images/network.svg';
import chat from '../../images/talking.svg';
import target from '../../images/target.svg';
import purpTarget from '../../images/purpTarget.svg';
import outlineTarget from '../../images/outlinePurpTarget.svg';
import nodes from '../../images/nodes.svg';
import calendar from '../../images/calendar.svg';
import  checks from '../../images/checks.svg';
import cloud from '../../images/cloudNetwork.svg';
import list from '../../images/list.svg';
import movement from '../../images/movements.svg';
import think from '../../images/think.svg';
import truck from '../../images/truck.svg';
import keyboard from '../../images/plugin.svg';
import plugin from '../../images/plugin.svg';
import smart from '../../images/smart.svg';
import warehouse from '../../images/warehouse.svg';
import screenMap from '../../images/png/screenMapSquare.png';
import screenChat from '../../images/png/screenSharpChat.png';
// import talking from '../../images/talking.svg';

export function Home() {
	const [loadTracking, setLoadTracking] = useState(false);
	const [didFailLoading, setDidFailLoading] = useState(false);
	const [input, setInput] = useState('')
	const whyRef = useRef(null);
	const { state, pathname } = useLocation();

	let destination;

	const navigation = useNavigate()

	function onMouseDown(event, nav){
		if (event.button === 0){
			event.preventDefault();
			// document.querySelector('#app-container').scrollTo(0, 0)
			navigation(nav)
		}
	}

	function onMouseDownLink(event){
		if (event.button === 0){
			event.preventDefault();
			event.stopPropagation();
			window.open('https://developers.google.com/maps')
		}
	}

	async function onKeyDown(event){

		if (event.key ==='Enter' && input.length > 0){
			event.preventDefault();
	
			const tracking = input
			setInput('');
			// setLoadTracking(true)
			const result = await getShipments({ tracking }); // ios prod

			console.log(result)
			const shipment = result?.pop()

			// setLoadTracking(false)

			if (shipment) {
				navigation(`/dashboard/shipments/${shipment.tracking ?? shipment.tracking_number}`, { state: { shipment } })
			}
			else {
				setDidFailLoading(true)
				console.log("BAD TRACKING")
			}
		} else {
			setDidFailLoading(false);
		}
	};

	useEffect(() => {
		if (state?.destination){
			const node = document.getElementById(state.destination);
			node?.scrollIntoView({behavior: 'smooth'});
			window.history.replaceState({}, '')
		}

		return() => {
		};
	}, [state]);

	const onClick = async (e) => {
		e.preventDefault();

		if (!input.length) {
			return;
		}
		const tracking = input
		setInput('');
		setLoadTracking(true)
		const result = await getShipments({ tracking }); // ios prod

		console.log(result)
		const shipment = result?.pop()

		setLoadTracking(false)

		if (shipment) {
			navigation(`/dashboard/shipments/${shipment.tracking_number ?? shipment.tracking}`, { state: { shipment } })
		}
		else {
			console.log("BAD TRACKING")
		}
	};

	if (loadTracking) {
		return <>Loading</>
	}

	return (
		<Box id='home' textAlign='' flex={1}>
			<Box width='100vw' mt='4vh' display='flex' flex={1} alignItems='center' backgroundColor='' zIndex={90}>
				{/* <Box className='background-wave' display='flex' alignItems='center' flex={1} backgroundColor='#e9e9e9' m='1em '> */}
				<Box className='background-purp slide-left' display='flex' alignItems='center' flex={1} backgroundColor='white' m=''>
					{/* <Typography variant="h6" paragraph sx={{ color: '#666' }}>
						Delivering Value & Driving Growth
					</Typography> */}
					{/* <Box className='background-wave' textAlign=""  flex={1}> */}
				</Box>
				<Box className='' borderRadius='' display='flex' flexDirection='column' alignItems='center' px='.5em'>
					<Typography color='' variant="h2" align='center' fontWeight={600}>
						{/* <Typography color='#512aeb' variant="h2" align='center' fontWeight={600}> */}
						<strong>Photon</strong>
					</Typography>
					<Typography variant="h2" align='center' fontWeight={600} >
						<strong>Freight</strong>
					</Typography>
					<Typography variant="body1" color='gray' align='center'>
						{/* Delivering Value  & Driving Growth */}
						Light Speed Logistics
					</Typography>
					<Typography variant="body2" color='gray' align='center'>
						3PL Provider
					</Typography>
				</Box>
				<Box className='background-purp slide-right' display='flex' alignItems='center' flex={1} backgroundColor='white' m=''>
					{/* <Typography variant="h6" paragraph sx={{ color: '#666' }}>
						Delivering Value & Driving Growth
					</Typography> */}
					{/* <Box className='background-wave' textAlign=""  flex={1}> */}
				</Box>
			</Box>

			<Box maxWidth="1200px" textAlign='' margin='auto' flex={1}>
				<Box textAlign="center" sx={{ display: "flex", flexDirection: 'column', flex: 1, my: '4em' }} autoComplete="off">
					<TextField
						fullWidth
						size='small'
						id="outlined-textarea"
						label="Tracking Number"
						placeholder="23345-001"
						value={input}
						onChange={(e) => setInput(e.target.value)}
						onKeyDown={onKeyDown}
						onBlur={() => setDidFailLoading(false)}
					/>
				{ didFailLoading && <Typography variant='body1' color='red' align='left'><strong>Invalid Tracking Number</strong></Typography> }
				</Box>

				
				{/* <HoverElevate onMouseDown={(e) => onMouseDown(e, '/technology')} title='Live Freight Tracking' src={smart} > */}
				<HoverElevate onMouseDown={(e) => onMouseDown(e, '/technology')} title='Live Freight Tracking' src={screenMap}>
				{/* <HoverElevate onMouseDown={(e) => onMouseDown(e, '/technology')} title='Live Freight Tracking' src="https://upload.wikimedia.org/wikipedia/commons/0/09/Map_of_current_US_Routes.svg" > */}
					Ship Photon and never lose sight of your freight. Utilize live freight tracking for real-time visibility into your shipments. Our up-to-the-minute data enables us to take a proactive approach to your logistics solutions. 
				</HoverElevate>

				{/* <Divider orientation="horizontal" sx={{ my: '4em', }} flexItem /> */}

				<Box height='4em'></Box>
				<HoverElevate onMouseDown={(e) => onMouseDown(e, '/technology')} title='Instant Messaging Interface' src={screenChat} flip >
				{/* <HoverElevate onMouseDown={(e) => onMouseDown(e, '/technology')} title='Effective Communication Interface' src={chat} flip > */}
				{/* <HoverElevate onMouseDown={(e) => onMouseDown(e, '/technology')} title='Effective Communication Interface' src="https://upload.wikimedia.org/wikipedia/commons/1/12/Cloud_computing_icon.svg" flip > */}
					We implement an active, healthy network to help streamline your logistics. Our software facilitates a constant data flow to prevent communication lapses and improve overall supply chain coordination. 
				</HoverElevate>

				<Divider id='why' orientation="horizontal" sx={{ my: '4em', }} flexItem />

				<Typography align='center' variant="h3"><strong>Why Photon Logistics?</strong> </Typography>
				<Typography align='center' variant="h6" color='gray' paragraph>Digitize your supply chain</Typography>
				<TrmGrid onMouseDown={(e) => onMouseDown(e, '/technology')}></TrmGrid>

				<Box height='4em'></Box>
				<CanWeHelp onMouseDown={onMouseDown}></CanWeHelp>
				<Box id='demo' height='4em'></Box>
				{/* <Divider id='demo' orientation="horizontal" sx={{ my: '2em', }} flexItem /> */}
				<Demo></Demo>
			</Box>
		</Box>
	);
};

function HoverElevate({ children, title, src, onMouseDown, flip = false }){
	let left = 
	<Box className='elevate-content' key='box' >

		<Typography color='#512aeb' variant="h5" fontWeight={600} gutterBottom >{title}</Typography>
		<Typography variant="body1" paragraph>
			{children}
		</Typography>
		<Typography variant="body1" color='gray' paragraph>Learn more &#x21E8;</Typography>
	</Box>

	let right =
	<Box className='elevate-image' key='image'>
		<img  src={src} loading="lazy" alt="" ></img>
	</Box>

	if (flip){
		[left, right] = [right, left]
	}
	
	return (
		<Paper onMouseDown={onMouseDown} className='hover-elevate' sx={{ }} elevation={0}>
			{left}
			
			<Divider className='divider-vert' orientation="horizontal" sx={{my: '2em'}} />
			<Box className='mydiv'></Box>
			{right}
		</Paper>
	);
}

function TrmGrid({ onMouseDown}){
	function GridItem({ children, header, src }) {
		return (
			<Box id='grid-item' className='grid-item'>
				{/* <Box className='icon62'>
					<img src={'https://upload.wikimedia.org/wikipedia/commons/0/06/Network-receive.svg'} alt="We Deliver" className='responsive-image' />
				</Box> */}
				<Box className='icon62'>
					<img src={src} alt="We Deliver" className='responsive-image' />
				</Box>
				<Box width='.5em'></Box>
				<Box flex={1}  minWidth='250px'>
					<Typography sx={{overflowWrap: 'anywhere', }} color='#512aeb' variant="h6" gutterBottom  > {header}</Typography>
					{/* <Typography color='#052ef9' variant="h6" gutterBottom > {header}</Typography> */}
					<Box flex={1} sx={{overflowWrap: 'anywhere'}}>{children}</Box>
				</Box>
			</Box>
		);
	}

	return(
		<Box className='mygrid' >
			<Box className='grid-row' display='flex' flexDirection='row'>
				<GridItem src={target} header='Precise, accurate freight monitor'><Typography>We work for you to know more while doing less. Partners use our technology to optimize routes, reduce operational costs, and enhance customer satisfaction -- all while never losing site of your freight!</Typography></GridItem>
				<Divider orientation='vertical' flexItem />
			{/* <Divider className='divider-vert' orientation="horizontal" sx={{my: '2em'}} /> */}
				<GridItem src={cloud} header='Transparent communication network'>
					<Typography>
						Rapid information sharing defines our digital age. We reinforce your supply chain with an open channel for proactive communcation.
					</Typography>
				</GridItem>
			</Box>

			<Box className='' display='flex' flexDirection='row' px={'1em'} py={'1em'} sx={{alignSelf: 'stretch'}}>
				<Box className='grid-divider' pr='2em'>
					<Divider orientation="horizontal" flexItem />
				</Box>
				<Box className='grid-divider' pl='2em'>
					<Divider orientation="horizontal" flexItem />
				</Box>
			</Box>

			<Box className='grid-row' display='flex' flexDirection='row'>
				<GridItem src={nodes} header='Custom solutions for your workflow'>
					<Typography>
						Our proprietary software makes Photon Freight unique. We actively collaborate with partners to gather feedback and test new ideas. Please share your suggestions and help shape the future of supply chain technology.
					</Typography>
				</GridItem>
				<Divider orientation='vertical' flexItem />
				<GridItem src={think} header='Deliberate, effective planning & organization'>
					<Typography>
						 We promote enhanced coordination among all stakeholders with fast, accurate information. Partner with Photon and let us start you digital transformation. Know more. Do less. 
					</Typography>
				</GridItem>
			</Box>
			<Typography  className='learn-more' onMouseDown={onMouseDown} variant="body1" color='gray' align='center' paragraph>Learn more about how we help digitize your supply chain &#x21E8;</Typography>
		</Box>
	);
};

function CanWeHelp({ onMouseDown }){
	function HelpPanel({ children, header, onMouseDown, src}) {
		return (
			<Box onMouseDown={onMouseDown} flexDirection='row' display='flex' className='can-help-panel' flex={1} >
				{/* <Box className='icon42' >
					<img src={'https://upload.wikimedia.org/wikipedia/commons/0/06/Network-receive.svg'} alt="We Deliver" className='responsive-image' />
				</Box> */}
				<Box className='icon42' >
					<img src={src} alt="We Deliver" className='responsive-image'/>
				</Box>
				<Box className='' ml='1em' flex={1}>
					<Typography variant='h6'  gutterBottom>{header}</Typography>
					<Box backgroundColor='' flex={1}>{children}</Box>
					<Typography className='help-link' variant="body1" color='gray'>Learn more &#x21E8;</Typography>
				</Box>
			</Box>
		);
	}

	return(
		<Box className='can-help' backgroundColor='#F7F7F7' >
			<Box className='title'>
				<Typography fontWeight={600} variant='h3' gutterBottom>How can we help you?</Typography>
				<Typography variant='h6'>Learn how Photon Logistics can help digitize your supply chain today!</Typography>
			</Box>
			<Box className='space-vert'></Box>
			{/* <Box></Box> */}
			<Box className='can-help-panel-list'>
				<HelpPanel src={warehouse} header='Freight Shippers' onMouseDown={e => onMouseDown(e, '/shippers')}>
					<Typography variant='body1' paragraph>Secure and track your freight with less effort. We'll manage your logistics while you focus on business operations.</Typography>
				</HelpPanel>
				<Divider orientation="horizontal" sx={{ my: '1em', mx:'auto'}} flexItem />
				<HelpPanel src={truck} header='Fleet/Owner Operators' onMouseDown={e => onMouseDown(e, '/carriers')}>
					<Typography variant='body1' paragraph>Leverage our communcation technology to optimize fleet deployment. Use effective communication to save time and keep drivers on the road.</Typography>
				</HelpPanel>
				<Divider orientation="horizontal" sx={{ my: '1em', }} flexItem />
				<HelpPanel src={plugin} header="Brokerage Network" onMouseDown={e => onMouseDown(e, '/technology')}>
					<Typography variant='body1' paragraph>Learn how Photon Logistics can help change your business today!</Typography>
				</HelpPanel>
			</Box>
		</Box>
	);
}

function Demo({ onMouseDown }){
	return(
		<Box  className='can-help' display='flex' >
			<Box className='title'  mb='2em' >
				<Typography fontWeight={600} variant='h3' gutterBottom>Digitize your supply chain?</Typography>
				<Typography variant='h6'>Tell us about your supply chain! Together, we can discover how our software can automate your business.</Typography>
			</Box>
			<Box flex={1}>
				<QuoteForm></QuoteForm>
			</Box>
		</Box>
	);
}
/*

This website uses cookies and analytics technologies

We do this to better understand how visitors use our site and to offer you a more personal experience. 
By using our website, you consent to the recording, use, and sharing of your website activity by us and our service providers.
Please see our Privacy Notice for more information. You can manage your preferences by selecting Cookie Settings.

*/

/*
(ship bob)
By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
 [Cookies Settings] [Reject All] [Accept All Cookies]

*/

/*
(flexport)
Your Privacy
Welcome to Flexport! We’re glad you’re here and want you to know that we respect your privacy and your right to control how we collect, use, and share your personal data
*/