import React, { useEffect, useState, useRef } from 'react';

import { Container, Typography, Button, Box, Grid, Paper, Divider, TextField, styled } from '@mui/material';

function FlexCol({ children }) {
    const ShipmentDetailContainer = styled(Box)({
        // display: 'flex',
        // flexDirection: 'column',
        //paddingBottom: '15px',
    });
    return (
        <ShipmentDetailContainer>{children}</ShipmentDetailContainer>
    );
}

function Detail({ align, head, children }) {
    return (
        <Box display='flex' justifyContent={align}>
            <Box display='flex' flexDirection='column'>


                <Typography flex={0} align="left" variant="h6">

                    <strong>{head}</strong>
                </Typography>
                <Typography variant="body1">
                    {children}
                </Typography>
            </Box>
        </Box>
    );
}

export function Shippers() {
      const [mobileView, setMobileView] = useState(false);

    const handleResize = () => {
        if (window.innerWidth < 960) {
        setMobileView(true);
        } else {
        setMobileView(false);
        }
    };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);
  



    return (
        <Box display='flex' backgroundColor='' height='100%' gap='1em' px='2em' flexWrap='wrap' overflow='scroll'>
            <Box m='auto' flex={.7} backgroundColor='' minWidth='350px'>

                <Typography id='title' variant="h3" component='' fontWeight={500} align="center" > <strong>Digitize Your Supply Chain</strong></Typography>
                <Typography minWidth='50%' variant='body1' fontWeight={600} align='center'>
                    Staying ahead means embracing digital transformation.<br /> Digitizing your supply chain isn’t just a trend – it’s a necessity.
                </Typography>
            </Box>

            { !mobileView && <Divider orientation="vertical" sx={{ m: 'auto', height: '90%' }}></Divider>}

            <Box backgroundColor='#f7f7f7' my='auto' p='.5em' sx={{}} flex={1} display='flex' flexDirection='column' gap='1.5em'>

                <Box >
                <Typography variant="h5" fontWeight={600} align="center">Photon promises ...</Typography>
                    <Divider orientation="horizontal" sx={{ width: '90%', m: '1em auto' }}></Divider>
                </Box>

                <Box backgroundColor='' mx='auto' sx={{}} width='80%' display='flex' flexDirection='column' gap='.5em'>

                    <Detail align='left' head='Reduced Shipping Costs' body=''>Optimize operations, reduce delays, and cut costs</Detail>

                    <Detail align='right' head='Enhanced Visibility' >
                        Gain real-time insights into every stage of your supply chain.
                    </Detail>

                    <Detail head="Storage, Organization, & Compliance">
                        Automate compliance through document organization and persistance.
                    </Detail>

                    <Detail head="Data-Driven Technology" align='right'>
                        Use analytics to identify bottlenecks and improve processes.
                    </Detail>

                    <Detail head="Improved Risk Management" >
                        Quickly address disruptions, ensuring secure and compliant operations.
                    </Detail>

                </Box>

                <Box >
                    {/* <Divider orientation="horizontal" sx={{ width: '90%', m: '1em auto' }}></Divider> */}
                </Box>

                <Box >
                    <Divider orientation="horizontal" sx={{ width: '90%', m: '1em auto' }}></Divider>
                </Box>

                <Box width='90%' m='auto'>
                    <Typography variant="h6" fontWeight={600}>
                        Get started with live tracking today
                    </Typography>
                    <Typography variant="body1">
                        Start your digital transformation with live freight tracking today! Enjoy immediate benefits while establishing a strong foundation for a digital future.
                    </Typography>

                </Box>
                <Details>
                    <Typography></Typography>
                </Details>
            </Box>

            {/* <Divider orientation="horizontal" sx={{ width: '90%', m: '1em auto' }} flexItem></Divider> */}

        </Box>
    );
}

export function Shipperss() {
    return (
        // <FlexCol>

        //     < Box sx={{display: 'flex', backgroundColor:'#f7f7f7', alignContent:'start', flexDirection: 'row', }}>


        <Box sx={{}} display='flex' p='1em' flexDirection='column'>
            <Box display='flex' min-height='40vh' flexDirection='column' alignItems='center'>
                <Typography id='title' variant="h3" component='' fontWeight={500} align="center" > Digitize Your Supply Chain</Typography>
                <Typography minWidth='50%' variant='body1' fontWeight={500} align='center'>
                    Staying ahead means embracing digital transformation. Digitizing your supply chain isn’t just a trend – it’s a necessity.
                </Typography>
            </Box>

            <Box backgroundColor='#fafafa' my='1em' p='.5em' sx={{}}>
                <Typography variant="h5" fontWeight={500} align="center">Why Supply Chain Digitization is Essential</Typography>
                <Typography variant="body1">
                    <strong>Enhanced Visibility:</strong> Gain real-time insights into every stage of your supply chain.
                </Typography>
                <Typography variant="body1"><br />
                    <strong>Improved Efficiency: </strong>Optimize operations, reduce delays, and cut costs.
                </Typography>
                <Typography variant="body1"><br />
                    <strong>Data-Driven Decisions: </strong>Use analytics to identify bottlenecks and improve processes.
                </Typography>
                <Typography variant="body1"><br />

                    <strong>Customer Satisfaction:</strong> Meet the demand for real-time information and reliable delivery times.
                </Typography>
                <Typography variant="body1"><br />

                    <strong>Risk Management:</strong> Quickly address disruptions, ensuring secure and compliant operations.
                </Typography>
                <Typography variant="body1">
                    Why Live Tracking is the Foundation
                </Typography>
                <Typography variant="body1">
                    Starting your digital transformation with live freight tracking provides immediate benefits and sets a strong foundation for further digitization:
                </Typography>
                <Details>
                    <Typography></Typography>
                </Details>
            </Box>
            <Box sx={{}} p='4em' display='flex' justifyContent='center' alignItems='center' backgroundColor='teal' flex={1}>
                <Box backgroundColor='magenta'>
                    <Typography variant="h4" fontWeight={500} gutterBottom> Start your digitization with Photon</Typography>
                    <Typography variant="body1">
                        Real-Time Updates: Monitor shipments in real-time, offering transparency and reducing uncertainties.
                    </Typography>
                    <Typography variant="body1" color='gray'>
                        Learn More &#x21E8;
                    </Typography>
                </Box>
            </Box>




            {/* <Box backgroundColor='pink' flexBasis='100%'>
                    
                    <Typography width='60%'  textAlign='left' backgroundColor='limegreen' variant="body1">
                    
                        Real-Time Updates: Monitor shipments in real-time, offering transparency and reducing uncertainties.
                        Route Optimization: Improve efficiency and lower costs by optimizing delivery routes.
                        Customer Trust: Provide accurate ETAs and proactive updates, enhancing customer satisfaction.
                        Security: Reduce theft and loss by tracking freight continuously.
                        Data Collection: Gather valuable data to drive future improvements and digital initiatives.
                        Start Your Digital Transformation Today
                        Begin your journey to a fully digitized supply chain with live freight tracking. Experience enhanced visibility, improved efficiency, and superior customer satisfaction. Transform your logistics for a smarter, more connected future.
                    </Typography>
                </Box> */}

        </Box>
    );
};

function Details({ children, prepend, append }) {
    const styles = {
        main: {
            backgroundColor: 'lightcyan',
        }
    }
    return (
        <Box style={styles.main}>
            {prepend}
            {children}
            {append}
        </Box>
    );
};