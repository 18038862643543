import React, { useEffect } from "react";
import { styled } from '@mui/system';

import { Container, Typography, Button, Box, Grid, Paper, Divider, TextField } from '@mui/material';

function FlexCol({ children }){
    const ShipmentDetailContainer = styled(Box)({
        // display: 'flex',
        // flexDirection: 'column',
        //paddingBottom: '15px',
    });
    return (
        <ShipmentDetailContainer>{children}</ShipmentDetailContainer>
    );
}

function QrCode({children, name}){
    return (
        <FlexCol alignItems='center' align='center' m='2em'>
            <Typography variant="h5" align="center" fontWeight={600}>{name}</Typography>
            <Divider align='center' width='60%' orientation="horizontal" sx={{ m:'.4em auto 1em', fontWeight:600, }}/>
            <Box sx={{height: '113px', width: '113px', backgroundColor: 'white', border:'2px solid gray'}}>
            </Box>
        </FlexCol>
    );
}
export function Carriers(){
    useEffect(()=>{

        setTimeout(() => {
            window.scrollTo(0,0)

        }, 100)
    }, []);

    return (

        < FlexCol sx={{ backgroundColor:'', alignItems:'center', justifyContent: 'center'}}>
            <Box sx={{}} display='flex' p='2em' justifyContent='center' alignItems='center'>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    
                    <Typography variant="h3" fontWeight={500} align="center" gutterBottom>We respect your time and hard work</Typography>
                    
                    <Typography width='60%' variant='h5' fontWeight={500} align='center'>
                        Download or mobile app and start hauling with Photon today
                    </Typography>
                    <Box display='flex' mt='1em'>
                        <QrCode name='Apple'></QrCode>
                        <Divider orientation="vertical" sx={{mx: '2em'}} flexItem/>
                        {/* <Typography variant="h5">Android</Typography> */}
                        <QrCode name='Android'></QrCode>
                    </Box>
                </Box>
            </Box>
                        
            <Divider orientation="horizontal" sx={{maxidth: '1200px', mx:'auto'}} flexItem/>

            <Box backgroundColor='#f7f7f7' maxWidth='1200px' p='2em' m='2em auto' sx={{}}>
                    <Typography variant="h4" fontWeight={500} align="center">Why partner with Photon Freight?</Typography>
                <Typography  variant="h6" fontWeight={600} >Relationship</Typography>
                <Typography  variant="body1" paragraph>
                    We respect your time and commitments to others. We understand you do not work for us.
                </Typography>
                <Typography  variant="h6" fontWeight={600} >Profit</Typography>
                <Typography  variant="body1" paragraph>
                    Photon maintains the trust and reiliability of large brokers like TQL and XPO, but we respect your time and appreciate your efforts. Our rates prove this!
                </Typography>
                <Typography  variant="h6" fontWeight={600} >Accountability</Typography>
                <Typography  variant="body1" paragraph>
                    Use our tracking to account for every minute of your day. If you have to wait, we pay for your time. 
                </Typography>
                <Typography  variant="h6" fontWeight={600} >Transparency</Typography>
                <Typography  variant="body1" paragraph>
                   
                   Know yard times, freight status, poc availability, etc to make sure you can keep your truck moving on your terms
                </Typography>
                <Typography variant="body1" paragraph><strong>and most most importantly ...</strong></Typography>

                <Typography  variant="h6" fontWeight={600} >Respect</Typography>
                <Typography  variant="body1">
                   We respect and appreciate your hard work, and we priortitize letting you know it. 
                </Typography>
            </Box>
            {/* <Box backgroundColor='pink' flexBasis='100%'>
                
                <Typography width='60%'  textAlign='left' backgroundColor='limegreen' variant="body1">
                
                    Real-Time Updates: Monitor shipments in real-time, offering transparency and reducing uncertainties.
                    Route Optimization: Improve efficiency and lower costs by optimizing delivery routes.
                    Customer Trust: Provide accurate ETAs and proactive updates, enhancing customer satisfaction.
                    Security: Reduce theft and loss by tracking freight continuously.
                    Data Collection: Gather valuable data to drive future improvements and digital initiatives.
                    Start Your Digital Transformation Today
                    Begin your journey to a fully digitized supply chain with live freight tracking. Experience enhanced visibility, improved efficiency, and superior customer satisfaction. Transform your logistics for a smarter, more connected future.
                </Typography>
            </Box> */}

        </FlexCol>
    );
};

function Details({ children, prepend, append}){
    const styles = {
        main: {
            backgroundColor: 'lightcyan',
        }
    }
    return (
        <Box style={styles.main}>
            {prepend}
            {children}
            {append}
        </Box>
    );
};