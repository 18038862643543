/* global google */

import React, { useEffect, useState, useRef} from 'react';
import { listAndCreateRequestedShipments, getShipments, getTrips, getGps } from '../../services/ApiService'

 export function GoogleMap({ connection, trip, setRoute}){
	const [isScriptLoaded, setScriptLoaded] = useState(false);
	const [gps, setGps] = useState([]);
	const [pendingPath , setPendingPath] = useState([])
	const isload = isScriptLoaded;

	const mapRef = useRef(null);
	const pendingPathRef = useRef(null);

	// TODO: use same connection for messages
	useEffect(() => {
		let ignore = false;

		const fetchGps = async () => {
			// const result = points // query for gps data
			// const result = await getGps({trip_id: 1}) // query for gps data
			if (!ignore) {
				//setGps(result)
			}
		};

		fetchGps();

		return () => {
			ignore = true;
		}
	}, []);

	useEffect(() => {
		if (!connection){
			return
		}

	    const removeLocation = connection.on('location', (e) => {
	        console.log('location', JSON.parse(e?.data))
	        let data = JSON.parse(e.data);

	        setGps(prevGps => [...prevGps, ...data.data2]);
	        setPendingPath(prevGps => {
				return prevGps.length === 0 ?
					[...data.data2] : 
					[prevGps[prevGps.length - 1], ...data.data2]
			});
	    });

	    const removeHandlerCallbacks = [];
	    removeHandlerCallbacks.push(removeLocation);

	    return () => {
	        removeHandlerCallbacks.forEach(callback => {
	            callback?.();
	        });
	    }
    }, [connection]);

	useEffect(() => {

		/*     console.log('google mounted');
			console.log("Loaded goog script?" + isScriptLoaded);
			console.log(window.google) */

		const initMap = async () => {
			const origin = { lat: 47.320580, lng: -122.884280 }; // Allyn, WA Post Office
			const destination = { lat: 47.214098, lng: -123.100707 }; // Shelton, WA Post Office

			const dest = {}


console.log("trip", trip.stops.slice(-1))
			const originn = {lat: parseFloat(trip.stops[0].location.latitude),lng: parseFloat(trip.stops[0].location.longitude)}
			const destinationn =   {lat:parseFloat( trip.stops.slice().pop().location.latitude),lng: parseFloat(trip.stops.slice().pop().location.longitude)}
console.log("origin", originn)
console.log("dest", destinationn)

			const { Map, Polyline } = await google.maps.importLibrary("maps");
			const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

			const map = new Map(document.getElementById('map'), {
				center: new google.maps.LatLng(originn.lat, originn.lng),
				//center: {lat: 0, lng: 0},
				mapId: "TRIP_MAP",
				zoom: 10,
			});

			mapRef.current = map





      // Define route
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();

      directionsRenderer.setMap(map);

      directionsService.route(
        {
          origin: originn,
          destination: destinationn,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
			let route = result.routes[0];
			setRoute(route)
			console.log('Direction service result', result)
			console.log("route", route)
			console.log(" serialized route", JSON.stringify( route));
          } else {
            console.error(`Error fetching directions ${result}`);
          }
        }
      );
				// const destMarker = new AdvancedMarkerElement({
				// 	map,
				// 	position: destinationn,
				// 	// content: pin.element
				// });
				const marker = new AdvancedMarkerElement({
					// map,
					// position: originn,
					// content: pin.element
				});

				const bounds = new google.maps.LatLngBounds();
				[originn, destinationn].forEach(point => bounds.extend(point));
				mapRef.current.fitBounds(bounds);
			// const traffficLayer = new google.maps.TrafficLayer()
			// traffficLayer.setMap(map)
		};

		if (!isScriptLoaded) {
			window.initMap = initMap; // Ensures the function is available globally
			setScriptLoaded(!!(window.google && window.google.maps));
		} else if (!mapRef.current) {
			initMap();
		} 

		return () => {
		}
	}, [isScriptLoaded]);

	useEffect(()=>{
		if (!mapRef.current || pendingPath.length < 2 ){
			return
		}
		console.log("gps len", gps.length)
		console.log("pending len", pendingPath.length)

		function animateCircle(line){
			let count = 0
			const intervalId = window.setInterval(() => {
				count = (count + 1) % 200;
				const icons = line.get("icons");

				if(count === 0){
					console.log("count === 0 clear interval")
					clearInterval(intervalId);
				} else if  (icons.length > 0 ){
					icons[0].offset = count / 2 + "%"
					line.set("icons", icons)
				} else {
					// IMPORTANT: race condition here. updateMap could set icons to [] and is already tryin to run new animation
					console.log("WARNINGNGNGNGNGNGN no icons on line: ", count)
					clearInterval(intervalId);
				}
			}, 100);
		}

		const updateMap = async () => {
			const { Polyline } = await google.maps.importLibrary("maps");
			const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
			const pathCoordinates = gps.map(point => ({ lat: point.latitudeSnapped ?? point.latitude, lng: point.longitudeSnapped ?? point.longitude, originalIndex: point.originalIndex }))
			const pendingCoordinates = pendingPath.map(point => ({ lat: point.latitudeSnapped ?? point.latitude, lng: point.longitudeSnapped ?? point.longitude, originalIndex: point.originalIndex }))
			if (pathCoordinates.length > 0 || pendingCoordinates.length > 0){

				if (pendingPathRef.current) {
					pendingPathRef.current.set("icons", [])
				}

			const lineSymbol = {
				path: google.maps.SymbolPath.CIRCLE,
				scale: 16,
				// strokeColor: '#34A853',
				strokeColor: "#EA4335",
				strokeOpacity: 1,
			};
				const snappedRoadPath = new Polyline({
					path: pendingCoordinates,
					geodesic: true,
					strokeColor: "#4285F4",
					strokeOpacity: .8,
					strokeWeight: 12,
					icons: [
						{
							icon: lineSymbol, 
							offset: "0%",
						},
					],
					map: mapRef.current,
				});

				pendingPathRef.current = snappedRoadPath

				animateCircle(snappedRoadPath)
	
				const bounds = new google.maps.LatLngBounds();
				pathCoordinates.forEach(point => bounds.extend(point));
				mapRef.current.fitBounds(bounds);
			}
			
			if (false){
				const pin = new PinElement({
					scale: 1,
				});
				
				// const marker = new AdvancedMarkerElement({
				// 	map: mapRef.current,
				// 	position: {lat:current.latitudeSnapped ?? current.lat, lng: current.longitudeSnapped ?? current.lng},
				// 	content: pin.element
				// });

				// markerRef.current.setPosition(new google.maps.LatLng(current.latitudeSnapped ?? current.lat, current.longitudeSnapped ?? current.lng))
			}
		}

		updateMap();
	}, [pendingPath]);

	return (
		<div id="map" style={{ width: '100%', height: '100%', backgroundColor: 'white' }}></div>
	);
};
