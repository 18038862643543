import React, {useEffect, useState} from 'react';
import { Avatar, Container, Typography, Button, Box, Paper, Grid, AppBar, Toolbar, InputBase, Input} from '@mui/material';
import { useNavigate, useLocation, useParams, useNavigation, Outlet, useLoaderData } from "react-router-dom";
import './Dashboard.css';
//import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';

export function Dashboard(){
  const [data, setData] = useState(null);
  // const { shipment } = useLoaderData()
  const navigation = useNavigate()
  
  return (
    <Box className="dashboard" display='flex' sx={{flexWrap: 'wrap',height: '100vh', maxWidth:'100vw', alignContent: 'start', alignItems: 'start', backgroundColor: 'cyan',}}>
      {/* <PrimarySearchAppBar /> */}
      <Box sx={{height: '3vh', minWidth: '70vw', flex: 1, backgroundColor: '#fafafa',  color: '#512ae5', display: 'flex', position:'sticky', top:0, zIndex:1}}>
      {/* <Box sx={{height: '3vh', minWidth: '70vw', flex: 1, backgroundColor: '#512ae5',  color: 'white', display: 'flex', position:'sticky', top:0, zIndex:1}}> */}
      {/* <Box sx={{height: '3vh', minWidth: '70vw', flex: 1, backgroundColor: '#4285F4',  color: 'white', display: 'flex', position:'sticky', top:0, zIndex:1}}> */}
        <Typography fontWeight={1000} fontSize={24} variant='body2' component='div' onClick={() => navigation('/')}> <span className="logo">Photon</span></Typography>
        <Box display='flex' flex={1} justifyContent='right'>
          <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
        </Box>
      </Box>

      <Box id='dashboard-content' sx={{display: 'flex', flex:1 ,  minWidth: '50vw', height: `calc(100% - 3vh)`, alignItems: 'start',  backgroundColor: 'white',}}>
      {/* <Box id='dashboard-content' display='flex' sx={{height: `calc(100% - 3vh)`, flexBasis:'100%'}}> */}
        <Outlet />
      </Box>
    </Box>
  );
};
