import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Typography, Box, Divider, } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation, useParams, useNavigation } from "react-router-dom";
import './Technology.css'
import screen from '../../images/png/screenSharp.png';

export function Technology({ }) {
	const { pathname } = useLocation();
  
	useEffect(() => {
		console.log(pathname)
		window.scrollTo(0, 0);

	}, [pathname]);
    
  	function onMouseDownLink(event){
		if (event.button === 0){
			event.preventDefault();
			event.stopPropagation();
			window.open('https://developers.google.com/maps')
		}
	}

    const tracking = <>
    Photon Freight offers a modern, digital 3PL experience. Our advanced tracking system is the foundation of our proprietary logistics technology. Real-time updates improve supply chain visibility and empowers you to run your business.
    We harness the power of the <Link underline='hover' onMouseDown={(e) => onMouseDownLink(e)} style={{ cursor: 'pointer', }}>Google Maps Platform</Link> to rapidly deliver dynamic, precise location data.</>

	const activity = 'Enhance communication for everyone involved in your logistics process. We prevent communication breakdowns to ensure timely status updates. With efficient communcation, we can counter the unpredictable nature of logistics with dynamic solutions.';
    // const activity = 'Our Trip Message Board is currently in development and aims to streamline communication between drivers, dispatchers, and customers. This channel will resolve common communication issues, ensure timely updates, and improve overall coordination.'
	return (
		// <Box display='flex' flexDirection='' flexWrap='wrap' alignItems='center' m='auto' maxWidth='100%' overflow='hidden' gap='1em'>
        <Box display='flex' backgroundColor='' height='100%' gap='1em' flexWrap='wrap' overflow='scroll' flex={1}>
			{/* <Box display='flex' m='1em'>
				<Box width='800px' height='450px' backgroundColor='teal'></Box>
			</Box> */}
			{/* <Divider orientation="horizontal" sx={{ my: '' }} flexItem /> */}
            {/* <Box display='flex' flexBasis='100%' flexWrap='wrap'> */}

                <Box id='app-screen'  mx='' flex={1} >
                    <img src={screen} ></img>
                </Box>
                {/* <Divider orientation='horizontal' sx={{maxWidth: '100%'}} flexItem></Divider> */}
                <Box id='description' pl='1em' display='flex' gap='2em' flexDirection='column'  justifyContent='start' >
                    <Box className='pane'>
                        <Typography align='left' variant='h4' gutterBottom><strong>Live Tracking</strong></Typography>
                        <Typography className='details' sx={{overflowWrap: ''}} variant="body1"> {tracking} </Typography>
                    </Box>
                    
                    <Divider orientation="horizontal" sx={{ my: '1em' }}  />
                    <Box className='pane'>

                        <Typography align='left' variant='h4' gutterBottom><strong>Instant Communication</strong></Typography>
                        <Typography className='details' sx={{overflowWrap: ''}} variant="body1"> {activity} </Typography>
                    </Box>
                </Box>
            {/* </Box> */}

                    {/* <Divider orientation="horizontal" sx={{ m: '1em auto' , width:'90%'}} flexItem /> */}
           {/* <Box id='panel-container'>
                <Panel 
                    title='Live Tracking'
                    description={tracking}>
                    <ul>
                        <Typography variant="body1" >
                        <li>Complete transparency with real-time updates</li>
                        <li>Interactive maps for easy monitoring</li>
                        <li>Accurate ETAs and automated customer updates</li>
                        <li>Optimized routes and reduced fuel consumption</li>
                        <li>Enhanced security with geofencing</li>
                        <li>Data-driven decision making</li>
                        </Typography>
                    </ul>
                </Panel>
            <Divider className='divider-vert' orientation="vertical" flexItem />
                <Panel 
                    title='Activity Panel'
                    description={activity}>

                            <ul>
                                <Typography variant="body1" >
                                <li>Improved communication flow.</li>
                                <li>Real-time updates on trip status.</li>
                                <li>Reduced miscommunications and errors.</li>
                                <li>Enhanced coordination among stakeholders.</li>
                                </Typography>
                            </ul>

                </Panel>

            </Box>  */}
			{/* <Box maxWidth='1000px' m='auto'>
				<Typography variant="body1" color='green' paragraph>
					We are continuously working on new features and improvements. Since all our development is done in-house, we actively collaborate with our customers to gather feedback and ideas. We encourage you to share your suggestions and help shape the future of our logistics technology suite.
				</Typography>
				<Typography variant="body2" paragraph>
					development scheduled ...
				</Typography>
				<Typography variant="h5" gutterBottom>
					Digital Documents & Paperwork
				</Typography>
				<Typography variant="body1" paragraph>
					Next up in our development pipeline are digital documents and paperwork, such as Bills of Lading (BOL) and other essential logistics documents. This feature will simplify document handling, reduce paperwork, and improve efficiency.
				</Typography>
			</Box> */}

			<Box>

			</Box>
		</Box>
	);
};


function Panel_bakcup({ children,title, description }){

    return (

        <Box className='panel' maxWidth='1200px' backgroundColor='' justifyContent='space-evenly'>
            <Box display='flex' flexDirection='column' flex={1} p='1em'>
                <Typography variant='h4' gutterBottom>{title}</Typography>
                <Typography variant="body1" paragraph>
                    {description} </Typography>
            </Box>

            <Divider orientation="vertical" sx={{ m: 'auto 2em', height: '75%' }} flexItem />

            <Box display='flex' flexDirection='column' flex={1} p='1em'>
                { children }
            </Box>
        </Box>
    );
}

function Panel({ children,title, description }){

    return (

        <Box className='panel'  backgroundColor='' justifyContent=''>
            <Box className='description' display='flex' flexDirection='column'  p=''>
                <Typography align='center' variant='h4' gutterBottom>{title}</Typography>
                <Typography sx={{overflowWrap: ''}} variant="body1"> {description} </Typography>
                { children }
            </Box>
        </Box>
    );
}