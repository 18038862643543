import React, { useRef, useState } from 'react';
import { Grid, TextField, Button, Paper, Typography, InputLabel, Divider, FormControl, Select, MenuItem, Box, Container } from '@mui/material';
import { postDemo } from '../../services/ApiService';
import emailjs from '@emailjs/browser';
import './Quote.css';

export function QuoteForm() {

	const form = useRef();
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};

	// const sendEmail = (e) => {
	//   e.preventDefault();
	//   setIsSubmitted(true);

	//   emailjs.sendForm('service_bf8ij6t', 'template_8qelazv', form.current, 'JAZU8ug_rFvicM_eF')
	//     .then((result) => {
	//       //Pop modal
	//       setIsSubmitted(true);

	//     }, (error) => {

	//     });
	// }

	function onKeyDown() { };

	async function sendEmail(e) {
		e.preventDefault();

		const formData = {
			firstName: form.current.firstName.value,
			lastName: form.current.lastName.value,
			email: form.current.email.value,
			companyName: form.current.companyName.value,
			monthlyShipments: form.current.monthlyShipments.value,
			phone: form.current.phone.value,
		};
	
		try {
		  	const response = await postDemo(formData);

			if (response){
				setIsSubmitted(true);
				localStorage.setItem('demo', JSON.stringify(response));
			}
			console.log("Demo", response)
		//   await emailjs.sendForm('service_bf8ij6t', 'template_8qelazv', form.current, 'JAZU8ug_rFvicM_eF');
			// pop modal
		} catch (error) {
		  	console.error('Error submitting form', error);
		}
	};

	return (
		<Paper className='paper-container' elevation={0}>
			<Typography variant="h3"><strong>Schedule a Demo</strong></Typography>
			<Paper elevation={0} className='form-paper'>

				{isSubmitted ? (
					<Grid item xs={12}>
						<Typography variant="h5" color='limegreen' gutterBottom> Thank you for your interest in partnering with Photon Freight.</Typography>
						<Typography variant="h7" > What to expect from here ...</Typography>
						<div >
							<ul>
								<li>A member of the Photon team will reach out to you.</li>
								<li>We'll ask a few questions to determine our chances at a successful partnership.</li>
								<li>Start integrating, automating, and saving today!</li>
							</ul>
						</div>
					</Grid>
				) : (
					<form ref={form} onSubmit={sendEmail}>
						<Grid container spacing={1.5}>
							{/* Column 1 */}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="first-name">First Name*</InputLabel>
								<TextField name='firstName' required={true} id="first-name" fullWidth size="small" variant="outlined" />
							</Grid>
							{/* Column 2 */}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="last-name">Last Name*</InputLabel>
								<TextField name='lastName' required={true} id="last-name" fullWidth size="small" variant="outlined" />
							</Grid>
							{/* Column 3 */}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="email">Email*</InputLabel>
								<TextField name='email' required={true} id="email" fullWidth size="small" variant="outlined" />
							</Grid>
							{/* Column 4 */}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="phone">Phone*</InputLabel>
								<TextField name='phone' required={true} id="phone" fullWidth size="small" variant="outlined" />
							</Grid>
							{/* Column 5 */}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="company">Company*</InputLabel>
								<TextField name='companyName' required={true} id="company" fullWidth size="small" variant="outlined" />
							</Grid>

							{/* Column 6 */}
							<Grid item xs={12} sm={6}>
								<InputLabel htmlFor="monthly_shipment_count">Monthly Shipments</InputLabel>
								<FormControl fullWidth size='small' variant='outlined'>
									<Select
										value={age}
										name="monthlyShipments"
										onChange={handleChange}
									>
										<MenuItem value={"Less than 10"}>Less Than 10</MenuItem>
										<MenuItem value={"Less Than 20"}>Less Than 20</MenuItem>
										<MenuItem value={"More than Twenty"}>More Than 20</MenuItem>
									</Select>
								</FormControl>

							</Grid>

							<Grid item xs={12}>
								<Divider style={{ width: '100%', margin: '10px 0px' }} />
							</Grid>

							{/* Full Width */}

							<Grid item xs={12} className='center-horizontally'>
								<small><em>
									We do not share your information in any capacity. All information available exclusively to employees of Photon Freight.<br />
									{/* *Terms and conditions apply. Offer valid for new customers only. */}
								</em></small>
							</Grid>
							<Grid item xs={12} className='center-horizontally'>
								<Button sx={{backgroundColor: '#512aeb', color: 'white'}} variant="contained" color="primary" type="submit" className='oval-button'>
									Request Demo
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			</Paper>
		</Paper>

	);
};
